import { EventEmitter } from '@angular/core';
import { Localization } from '../types/models/Localization';
import { Settings } from '../config/settings.service';
import * as i0 from "@angular/core";
import * as i1 from "../config/settings.service";
var Translations = /** @class */ (function () {
    /**
     * Translations Service Constructor.
     */
    function Translations(settings) {
        this.settings = settings;
        /**
         * Fired when active localization changes.
         */
        this.localizationChange = new EventEmitter;
        /**
         * Currently active localization.
         */
        this.localization = { model: new Localization(), lines: {} };
    }
    /**
     * Translate specified key.
     */
    Translations.prototype.t = function (transKey, values) {
        if (!transKey)
            return '';
        if (!this.translationsEnabled()) {
            return this.replacePlaceholders(transKey, values);
        }
        var translation = this.localization.lines[transKey.toLowerCase().trim()] || transKey;
        return this.replacePlaceholders(translation, values);
    };
    Translations.prototype.replacePlaceholders = function (message, values) {
        if (!values)
            return message;
        var keys = Object.keys(values);
        keys.forEach(function (key) {
            message = message.replace(':' + key, values[key]);
        });
        return message;
    };
    /**
     * Get currently active localization.
     */
    Translations.prototype.getActive = function () {
        return this.localization;
    };
    /**
     * Set active localization.
     */
    Translations.prototype.setLocalization = function (localization) {
        if (!localization || !localization.lines || !localization.model)
            return;
        if (this.localization.model.name === localization.model.name)
            return;
        localization.lines = this.objectKeysToLowerCase(localization.lines);
        this.localization = localization;
        this.localizationChange.emit();
    };
    Translations.prototype.objectKeysToLowerCase = function (object) {
        var newObject = {};
        Object.keys(object).forEach(function (key) {
            newObject[key.toLowerCase()] = object[key];
        });
        return newObject;
    };
    /**
     * Check if i18n functionality is enabled.
     */
    Translations.prototype.translationsEnabled = function () {
        return this.settings.get('i18n.enable');
    };
    Translations.ngInjectableDef = i0.defineInjectable({ factory: function Translations_Factory() { return new Translations(i0.inject(i1.Settings)); }, token: Translations, providedIn: "root" });
    return Translations;
}());
export { Translations };
