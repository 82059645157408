<div class="content" *ngIf="activeCategory$ | async as activeCategory else categoryList">
    <button
            class="sticker button-with-image"
            [class.no-label]="!activeCategory.list"
            [class.dark-bg]="activeCategory.darkBg"
            *ngFor="let name of getStickersIterable(activeCategory);"
            (click)="addSticker(activeCategory, name); button.blur()"
            #button
    >
        <img [src]="shapesTool.getStickerUrl(activeCategory, name)" alt="Sticker preview image">
        <span class="bottom-label" *ngIf="activeCategory.list" trans>{{name}}</span>
    </button>
</div>

<ng-template #categoryList>
    <div class="content">
        <div class="category button-with-image" *ngFor="let category of categories" (click)="openStickersCategory(category)">
            <img [src]="shapesTool.getStickerCategoryUrl(category)" alt="Sticker category preview image">
            <div class="bottom-label" trans>{{category.name}}</div>
        </div>
    </div>
</ng-template>

<!--<div class="stickers-list" *ngIf="activeCategory$ | async as activeCategory else categoryList">-->
<!--<button-->
<!--class="sticker button-with-image"-->
<!--[class.no-label]="!activeCategory.list"-->
<!--[class.dark-bg]="activeCategory.darkBg"-->
<!--*ngFor="let name of getStickersIterable(activeCategory);"-->
<!--(click)="addSticker(activeCategory, name); button.blur()"-->
<!--#button-->
<!--&gt;-->
<!--<img [src]="shapesTool.getStickerUrl(activeCategory, name)" alt="Sticker preview image">-->
<!--<span class="bottom-label" *ngIf="activeCategory.list" trans>{{name}}</span>-->
<!--</button>-->
<!--</div>-->