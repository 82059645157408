import { Keycodes } from './keycodes.enum';
import * as i0 from "@angular/core";
// TODO: refactor so keybinds are stored for a specific "listenOn" element instead of globally
var Keybinds = /** @class */ (function () {
    function Keybinds() {
        this.bindings = [];
    }
    Keybinds.prototype.add = function (keybind, callback) {
        this.bindings.push({ keybind: this.parseKeybindString(keybind), keybindString: keybind, callback: callback });
    };
    Keybinds.prototype.addWithPreventDefault = function (keybind, callback) {
        this.bindings.push({ keybind: this.parseKeybindString(keybind), keybindString: keybind, callback: callback, preventDefault: true });
    };
    Keybinds.prototype.listenOn = function (document) {
        var _this = this;
        document.addEventListener('keydown', function (e) {
            _this.executeBindings(e);
        });
    };
    Keybinds.prototype.executeBindings = function (e) {
        var _this = this;
        this.bindings.forEach(function (binding) {
            if (!_this.bindingMatches(binding.keybind, e))
                return;
            if (binding.preventDefault && e.preventDefault)
                e.preventDefault();
            binding.callback(e);
        });
    };
    Keybinds.prototype.bindingMatches = function (keybind, e) {
        return Keycodes[keybind.key.toUpperCase()] === e.keyCode && e.ctrlKey === keybind.ctrl && e.shiftKey === keybind.shift;
    };
    /**
     * Parse keybind string into object.
     */
    Keybinds.prototype.parseKeybindString = function (keybind) {
        var parts = keybind.trim().split('+');
        var parsed = { ctrl: false, shift: false, key: '' };
        parts.forEach(function (part) {
            part = part.trim().toLowerCase();
            if (part === 'ctrl') {
                parsed.ctrl = true;
            }
            else if (part === 'shift') {
                parsed.shift = true;
            }
            else {
                parsed.key = part;
            }
        });
        return parsed;
    };
    Keybinds.ngInjectableDef = i0.defineInjectable({ factory: function Keybinds_Factory() { return new Keybinds(); }, token: Keybinds, providedIn: "root" });
    return Keybinds;
}());
export { Keybinds };
