<div class="content">
    <div class="aspect-ratios">
        <div class="custom-ratio-container" *ngIf="!this.config.get('pixie.tools.crop.hideCustomControls')">
            <div class="inputs">
                <div class="input-container">
                    <label for="width" trans>Width</label>
                    <input type="number" id="width" [(ngModel)]="cropzoneWidth" (ngModelChange)="resizeCropzone()" min="1">
                </div>

                <div class="input-container">
                    <label for="height" trans>Height</label>
                    <input type="number" id="height" [(ngModel)]="cropzoneHeight" (ngModelChange)="resizeCropzone()" min="1">
                </div>
            </div>
        </div>
        <div class="aspect-ratio" *ngFor="let aspectRatio of aspectRatios" [class.selected]="cropZone.aspectRatio === aspectRatio" (click)="applyAspectRatio(aspectRatio)">
            <div class="preview" #ratioPreview [attr.data-ratio]="aspectRatio"></div>
            <div class="name" trans>{{aspectRatio}}</div>
        </div>
    </div>
</div>