import { CanvasService } from '../../canvas/canvas.service';
import { Settings } from 'common/core/config/settings.service';
import { FramePatternsService } from './frame-patterns.service';
import { ActiveFrameService } from './active-frame.service';
import { FrameBuilderService } from './frame-builder.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/core/config/settings.service";
import * as i2 from "../../canvas/canvas.service";
import * as i3 from "./frame-patterns.service";
import * as i4 from "./active-frame.service";
import * as i5 from "./frame-builder.service";
var FrameToolService = /** @class */ (function () {
    function FrameToolService(config, canvas, patterns, activeFrame, frameBuilder) {
        var _this = this;
        this.config = config;
        this.canvas = canvas;
        this.patterns = patterns;
        this.activeFrame = activeFrame;
        this.frameBuilder = frameBuilder;
        this.frames = [];
        this.config.onChange.subscribe(function () {
            _this.frames = _this.config.get('pixie.tools.frame.items');
        });
        this.canvas.state.loaded.subscribe(function () {
            _this.canvas.fabric().on('object:added', function () {
                _this.activeFrame.group && _this.activeFrame.group.moveTo(98);
            });
        });
    }
    /**
     * Add a new frame to canvas.
     */
    FrameToolService.prototype.add = function (frameName) {
        var frame = this.getByName(frameName);
        if (this.activeFrame.is(frame))
            return;
        if (this.activeFrame.exists()) {
            this.activeFrame.remove();
        }
        var size = this.calcFrameSize(frame.size.default);
        this.frameBuilder.build(frame, size);
    };
    /**
     * Resize active frame to specified value.
     */
    FrameToolService.prototype.resize = function (value) {
        var size = this.calcFrameSize(value);
        this.frameBuilder.resize(size);
        this.patterns.scale(size);
        this.canvas.render();
    };
    /**
     * Change active "basic" frame color.
     */
    FrameToolService.prototype.changeColor = function (value) {
        this.activeFrame.changeColor(value);
    };
    FrameToolService.prototype.remove = function () {
        this.activeFrame.remove();
    };
    /**
     * Get frame by specified name.
     */
    FrameToolService.prototype.getByName = function (frameName) {
        return this.getAll().find(function (frame) { return frame.name === frameName; });
    };
    /**
     * Get config of currently active frame.
     */
    FrameToolService.prototype.getActive = function () {
        return this.activeFrame.config;
    };
    FrameToolService.prototype.getAll = function () {
        return this.frames;
    };
    /**
     * Calculate frame size based on canvas size and specified percentage.
     */
    FrameToolService.prototype.calcFrameSize = function (percentage) {
        var min = Math.min(this.canvas.state.original.width, this.canvas.state.original.height);
        return ((percentage / 100) * min);
    };
    FrameToolService.ngInjectableDef = i0.defineInjectable({ factory: function FrameToolService_Factory() { return new FrameToolService(i0.inject(i1.Settings), i0.inject(i2.CanvasService), i0.inject(i3.FramePatternsService), i0.inject(i4.ActiveFrameService), i0.inject(i5.FrameBuilderService)); }, token: FrameToolService, providedIn: "root" });
    return FrameToolService;
}());
export { FrameToolService };
