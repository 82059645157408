/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-font-selector.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/forms";
import * as i6 from "./text-font-selector.component";
import * as i7 from "../../../../../common/core/ui/overlay-panel/overlay-panel.service";
var styles_TextFontSelectorComponent = [i0.styles];
var RenderType_TextFontSelectorComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TextFontSelectorComponent, data: {} });
export { RenderType_TextFontSelectorComponent as RenderType_TextFontSelectorComponent };
export function View_TextFontSelectorComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "select-font-button no-style"]], [[4, "fontFamily", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openGoogleFontsPanel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "keyboard-arrow-down"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_4 = "keyboard-arrow-down"; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.selectedFont$)); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.selectedFont$)); _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 6).inline; var currVal_3 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_2, currVal_3); }); }
export function View_TextFontSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "text-font-selector", [], null, null, null, View_TextFontSelectorComponent_0, RenderType_TextFontSelectorComponent)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.TextFontSelectorComponent]), i1.ɵdid(2, 49152, null, 0, i6.TextFontSelectorComponent, [i7.OverlayPanel], null, null)], null, null); }
var TextFontSelectorComponentNgFactory = i1.ɵccf("text-font-selector", i6.TextFontSelectorComponent, View_TextFontSelectorComponent_Host_0, {}, {}, []);
export { TextFontSelectorComponentNgFactory as TextFontSelectorComponentNgFactory };
