var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnDestroy, OnInit } from '@angular/core';
import { Settings } from 'common/core/config/settings.service';
import { DrawToolService } from '../../../../image-editor/tools/draw/draw-tool.service';
import { CanvasService } from '../../../../image-editor/canvas/canvas.service';
import { Select, Store } from '@ngxs/store';
import { DrawState } from '../../../state/draw/draw.state';
import { Observable, BehaviorSubject } from 'rxjs';
import { CloseBrushControls, MarkAsDirty, OpenBrushControls } from '../../../state/draw/draw.actions';
import { staticObjectConfig } from '../../../../image-editor/objects/static-object-config';
import { ObjectNames } from '../../../../image-editor/objects/object-names.enum';
var DrawDrawerComponent = /** @class */ (function () {
    function DrawDrawerComponent(drawTool, settings, canvas, config, store) {
        this.drawTool = drawTool;
        this.settings = settings;
        this.canvas = canvas;
        this.config = config;
        this.store = store;
        this.brushSizes$ = new BehaviorSubject([]);
        this.brushTypes$ = new BehaviorSubject([]);
        this.currentDrawings = [];
        this.brushSizes$.next(this.config.get('pixie.tools.draw.brushSizes'));
        this.brushTypes$.next(this.config.get('pixie.tools.draw.brushTypes'));
    }
    DrawDrawerComponent.prototype.ngOnInit = function () {
        this.drawTool.enable();
        this.canvas.fabric().on('object:added', this.maybeMarkAsDirty.bind(this));
        this.currentDrawings = this.getAllDrawings().map(function (obj) { return obj.data.id; });
    };
    DrawDrawerComponent.prototype.ngOnDestroy = function () {
        this.drawTool.disable();
        this.canvas.fabric().off('object:added', this.maybeMarkAsDirty.bind(this));
    };
    /**
     * Get absolute url for brush preview image.
     */
    DrawDrawerComponent.prototype.getBrushPreviewUrl = function (type) {
        var name = type.replace('Brush', '').toLowerCase();
        return this.settings.getAssetUrl('images/brushes/' + name + '-square.png');
    };
    /**
     * Set brush type and close controls subdrawer.
     */
    DrawDrawerComponent.prototype.setBrushType = function (type) {
        this.drawTool.setBrushType(type);
        this.store.dispatch(new CloseBrushControls());
    };
    DrawDrawerComponent.prototype.maybeMarkAsDirty = function () {
        var _this = this;
        var lastDrawing = this.getAllDrawings()
            .find(function (obj) { return _this.currentDrawings.indexOf(obj.data.id) === -1; });
        if (lastDrawing) {
            this.store.dispatch(new MarkAsDirty());
            lastDrawing.set(__assign({}, staticObjectConfig, { strokeWidth: lastDrawing.strokeWidth }));
            this.canvas.render();
        }
    };
    DrawDrawerComponent.prototype.openBrushControls = function () {
        this.store.dispatch(new OpenBrushControls());
    };
    DrawDrawerComponent.prototype.getAllDrawings = function () {
        return this.canvas.fabric().getObjects()
            .filter(function (obj) { return obj.name === ObjectNames.drawing.name; });
    };
    __decorate([
        Select(DrawState.brushControlsOpen),
        __metadata("design:type", Observable)
    ], DrawDrawerComponent.prototype, "brushControlsOpen$", void 0);
    return DrawDrawerComponent;
}());
export { DrawDrawerComponent };
