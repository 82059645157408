var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { InjectionToken } from '@angular/core';
import { defaultShapes } from './tools/shapes/default-shapes';
import { defaultStickers } from './tools/shapes/default-stickers';
import { BrushSizes, BrushTypes } from './tools/draw/draw-defaults';
import { EditorMode } from './enums/editor-mode.enum';
import { EditorTheme } from './enums/editor-theme.enum';
import { ControlPosition } from './enums/control-positions.enum';
import { DrawerName } from '../image-editor-ui/toolbar-controls/drawers/drawer-name.enum';
import { defaultObjectProps } from './objects/default-object-props';
export var MERGED_CONFIG = new InjectionToken('MERGED_CONFIG');
export var PIXIE_VERSION = '2.1.3';
export var DEFAULT_CONFIG = {
    selector: 'pixie-editor',
    textureSize: 4096,
    ui: {
        visible: true,
        mode: EditorMode.INLINE,
        theme: EditorTheme.LIGHT,
        allowEditorClose: true,
        allowZoom: true,
        toolbar: {
            hideOpenButton: false,
            hideCloseButton: true,
        },
        nav: {
            position: ControlPosition.TOP,
            replaceDefault: false,
            items: [
                { name: 'filter', icon: 'filter-custom', action: DrawerName.FILTER },
                { type: 'separator' },
                { name: 'resize', icon: 'resize-custom', action: DrawerName.RESIZE },
                { name: 'crop', icon: 'crop-custom', action: DrawerName.CROP },
                { name: 'transform', icon: 'transform-custom', action: DrawerName.TRANSFORM },
                { type: 'separator' },
                { name: 'draw', icon: 'pencil-custom', action: DrawerName.DRAW },
                { name: 'text', icon: 'text-box-custom', action: DrawerName.TEXT },
                { name: 'shapes', icon: 'polygon-custom', action: DrawerName.SHAPES },
                { name: 'stickers', icon: 'sticker-custom', action: DrawerName.STICKERS },
                { name: 'frame', icon: 'frame-custom', action: DrawerName.FRAME },
                { type: 'separator' },
                { name: 'corners', icon: 'rounded-corner-custom', action: DrawerName.CORNERS },
                { name: 'background', icon: 'background-custom', action: DrawerName.BACKGROUND },
                { name: 'merge', icon: 'merge-custom', action: DrawerName.MERGE },
            ]
        },
        openImageDialog: {
            show: true,
            sampleImages: [
                {
                    url: 'images/samples/sample1.jpg',
                    thumbnail: 'images/samples/sample1_thumbnail.jpg',
                },
                {
                    url: 'images/samples/sample2.jpg',
                    thumbnail: 'images/samples/sample2_thumbnail.jpg',
                },
                {
                    url: 'images/samples/sample3.jpg',
                    thumbnail: 'images/samples/sample3_thumbnail.jpg',
                },
            ]
        },
        colorPresets: {
            replaceDefault: false,
            items: [
                'rgb(0,0,0)',
                'rgb(255, 255, 255)',
                'rgb(242, 38, 19)',
                'rgb(249, 105, 14)',
                'rgb(253, 227, 167)',
                'rgb(4, 147, 114)',
                'rgb(30, 139, 195)',
                'rgb(142, 68, 173)',
            ],
        }
    },
    languages: {
        active: 'default',
    },
    googleFontsApiKey: 'AIzaSyDOrI6VJiMbR6XLvlp3CdCPZj1T2PzVkKs',
    objectDefaults: __assign({ transparentCorners: false, borderOpacityWhenMoving: 1, cornerStyle: 'circle', cornerColor: '#ccc', cornerStrokeColor: '#fff', cornerSize: 16, strokeWidth: 0.05, lockUniScaling: true }, defaultObjectProps),
    tools: {
        filter: {
            replaceDefault: false,
            items: [
                'grayscale',
                'blackWhite',
                'sharpen',
                'invert',
                'vintage',
                'polaroid',
                'kodachrome',
                'technicolor',
                'brownie',
                'sepia',
                'removeColor',
                'brightness',
                'gamma',
                'noise',
                'pixelate',
                'blur',
                'emboss',
                'blendColor',
            ]
        },
        crop: {
            replaceDefault: false,
            hideCustomControls: false,
            defaultRatio: '16:9',
            items: ['3:2', '5:3', '4:3', '5:4', '6:4', '7:5', '10:8', '16:9']
        },
        text: {
            defaultCategory: 'handwriting',
            defaultText: 'Double click to edit',
        },
        draw: {
            replaceDefault: false,
            brushSizes: BrushSizes,
            brushTypes: BrushTypes,
        },
        shapes: {
            replaceDefault: false,
            items: defaultShapes.slice(),
        },
        stickers: {
            replaceDefault: false,
            items: defaultStickers,
        },
        import: {
            validExtensions: ['png', 'jpg', 'jpeg', 'svg', 'json', 'gif'],
        },
        export: {
            defaultFormat: 'png',
            defaultQuality: 0.8,
            defaultName: 'image',
        },
        frame: {
            replaceDefault: false,
            items: [
                {
                    name: 'basic',
                    mode: 'basic',
                    size: {
                        min: 1,
                        max: 35,
                        default: 10,
                    }
                },
                {
                    name: 'pine',
                    mode: 'stretch',
                    size: {
                        min: 1,
                        max: 35,
                        default: 15,
                    }
                },
                {
                    name: 'oak',
                    mode: 'stretch',
                    size: {
                        min: 1,
                        max: 35,
                        default: 15,
                    }
                },
                {
                    name: 'rainbow',
                    mode: 'stretch',
                    size: {
                        min: 1,
                        max: 35,
                        default: 15,
                    }
                },
                {
                    name: 'grunge1',
                    display_name: 'grunge #1',
                    mode: 'stretch',
                    size: {
                        min: 1,
                        max: 35,
                        default: 15,
                    }
                },
                {
                    name: 'grunge2',
                    display_name: 'grunge #2',
                    mode: 'stretch',
                    size: {
                        min: 1,
                        max: 35,
                        default: 20,
                    }
                },
                {
                    name: 'ebony',
                    mode: 'stretch',
                    size: {
                        min: 1,
                        max: 35,
                        default: 15,
                    }
                },
                {
                    name: 'art1',
                    display_name: 'Art #1',
                    mode: 'repeat',
                    size: {
                        min: 10,
                        max: 70,
                        default: 55,
                    },
                },
                {
                    name: 'art2',
                    display_name: 'Art #2',
                    mode: 'repeat',
                    size: {
                        min: 10,
                        max: 70,
                        default: 55,
                    },
                }
            ]
        }
    }
};
