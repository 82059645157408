var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CanvasStateService } from '../canvas-state.service';
import { Subject } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { ActiveObjectForm } from './active-object.form';
import { getFabricObjectProps } from '../../utils/get-fabric-object-props';
import { Settings } from '../../../../common/core/config/settings.service';
import { normalizeObjectProps } from '../../utils/normalize-object-props';
import { randomString } from '../../../../common/core/utils/random-string';
import { ObjectNames } from '../../objects/object-names.enum';
var ActiveObjectService = /** @class */ (function () {
    function ActiveObjectService(fb, config, canvasState) {
        this.fb = fb;
        this.config = config;
        this.canvasState = canvasState;
        this.propsChanged$ = new Subject();
        this.form = ActiveObjectForm.build(this.fb);
    }
    ActiveObjectService.prototype.init = function () {
        var _this = this;
        // set default values before subscribing to changes
        this.syncForm();
        this.form.valueChanges
            .subscribe(function (values) {
            _this.setValues(values);
        });
    };
    /**
     * Check if active object (like i-text) is currently being edited by user.
     */
    ActiveObjectService.prototype.isEditing = function () {
        var text = this.get();
        return text && text.isEditing;
    };
    ActiveObjectService.prototype.setValues = function (values) {
        var obj = this.get();
        if (!obj)
            return;
        // apply fill color to each svg line separately, so sticker
        // is not recolored when other values like shadow change
        if (obj.name === ObjectNames.sticker.name && values.fill !== obj.fill) {
            if (obj.forEachObject) {
                obj.forEachObject(function (path) { return path.set('fill', values.fill); });
            }
        }
        this.propsChanged$.next();
        obj.set(normalizeObjectProps(values));
        this.canvasState.fabric.requestRenderAll();
    };
    ActiveObjectService.prototype.getValue = function (name) {
        return this.get().get(name);
    };
    ActiveObjectService.prototype.get = function () {
        var obj = this.canvasState.fabric && this.canvasState.fabric.getActiveObject();
        if (!obj || !obj.name)
            return null;
        if (obj.name.indexOf('crop.') > -1 || obj.name.indexOf('round.') > -1)
            return null;
        return obj;
    };
    ActiveObjectService.prototype.set = function (key, value) {
        var obj = this.get();
        if (obj) {
            obj.set(key, value);
            this.canvasState.fabric.requestRenderAll();
        }
    };
    ActiveObjectService.prototype.move = function (direction, amount) {
        var obj = this.get();
        if (!obj)
            return;
        obj.set(direction, obj[direction] + amount);
        this.canvasState.fabric.requestRenderAll();
    };
    ActiveObjectService.prototype.bringToFront = function () {
        var obj = this.get();
        if (!obj)
            return;
        obj.bringToFront();
        this.canvasState.fabric.requestRenderAll();
    };
    ActiveObjectService.prototype.sendToBack = function () {
        var obj = this.get();
        if (!obj)
            return;
        obj.sendToBack();
        this.canvasState.fabric.requestRenderAll();
    };
    ActiveObjectService.prototype.flipHorizontal = function () {
        var obj = this.get();
        if (!obj)
            return;
        obj.flipX = !obj.flipX;
        this.canvasState.fabric.requestRenderAll();
    };
    ActiveObjectService.prototype.duplicate = function () {
        var _this = this;
        var original = this.get();
        if (!original)
            return;
        this.deselect();
        original.clone(function (clonedObj) {
            clonedObj.set({
                left: original.left + 10,
                top: original.top + 10,
                data: __assign({}, original.data, { id: randomString(10) }),
                name: original.name,
            });
            _this.canvasState.fabric.add(clonedObj);
            _this.select(clonedObj);
            _this.canvasState.fabric.requestRenderAll();
        });
    };
    ActiveObjectService.prototype.getId = function () {
        var obj = this.get();
        return obj && obj.data ? obj.data.id : null;
    };
    /**
     * Delete currently active fabric object.
     */
    ActiveObjectService.prototype.delete = function () {
        var obj = this.get();
        if (!obj)
            return;
        this.canvasState.fabric.remove(obj);
        this.canvasState.fabric.requestRenderAll();
    };
    ActiveObjectService.prototype.deselect = function () {
        this.canvasState.fabric.discardActiveObject();
        this.canvasState.fabric.requestRenderAll();
    };
    ActiveObjectService.prototype.select = function (obj) {
        this.canvasState.fabric.setActiveObject(obj);
    };
    ActiveObjectService.prototype.syncForm = function () {
        if (this.get()) {
            this.form.patchValue(getFabricObjectProps(this.get()), { emitEvent: false });
        }
        else {
            this.form.patchValue(this.config.get('pixie.objectDefaults'));
        }
    };
    return ActiveObjectService;
}());
export { ActiveObjectService };
