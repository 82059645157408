/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./history-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../common/core/translations/translate.directive";
import * as i5 from "../../../../common/core/translations/translations.service";
import * as i6 from "../../../../common/core/config/settings.service";
import * as i7 from "../../../../common/core/ui/custom-scrollbar/custom-scrollbar.directive";
import * as i8 from "../../../../common/core/ui/breakpoints.service";
import * as i9 from "@angular/cdk/observers";
import * as i10 from "../../../../common/core/ui/custom-scrollbar/supports-native-scrollbar-styling";
import * as i11 from "@angular/common";
import * as i12 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i13 from "@angular/material/button";
import * as i14 from "@angular/cdk/platform";
import * as i15 from "@angular/cdk/a11y";
import * as i16 from "@angular/platform-browser/animations";
import * as i17 from "./history-panel.component";
import * as i18 from "../../../image-editor/history/history-tool.service";
import * as i19 from "../../../../common/core/ui/overlay-panel/overlay-panel-ref";
var styles_HistoryPanelComponent = [i0.styles];
var RenderType_HistoryPanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HistoryPanelComponent, data: {} });
export { RenderType_HistoryPanelComponent as RenderType_HistoryPanelComponent };
function View_HistoryPanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "floating-panel-item"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.loadHistoryState(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "list-icon mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "name"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(4, 4341760, null, 0, i4.TranslateDirective, [i1.ElementRef, i5.Translations, i6.Settings], null, null), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_3 = _v.context.$implicit.icon; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.id === _v.parent.context.ngIf); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 2).inline; var currVal_2 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_4 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_4); }); }
function View_HistoryPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "items-list"], ["customScrollbar", ""]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i7.CustomScrollbarDirective, [i1.ElementRef, i1.Renderer2, i8.BreakpointsService, i1.NgZone, i9.MutationObserverFactory, i6.Settings, i10.SUPPORTS_NATIVE_SCROLLBAR_STYLING], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_HistoryPanelComponent_2)), i1.ɵdid(3, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.items$)); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_HistoryPanelComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "name"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(2, 4341760, null, 0, i4.TranslateDirective, [i1.ElementRef, i5.Translations, i6.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["History"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["class", "close-button"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.panelRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_MatButton_0, i12.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i13.MatButton, [i1.ElementRef, i14.Platform, i15.FocusMonitor, [2, i16.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(7, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_HistoryPanelComponent_1)), i1.ɵdid(9, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_4 = "close"; _ck(_v, 7, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.activeItemId$)); _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 5).disabled || null); var currVal_1 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 7).inline; var currVal_3 = (((i1.ɵnov(_v, 7).color !== "primary") && (i1.ɵnov(_v, 7).color !== "accent")) && (i1.ɵnov(_v, 7).color !== "warn")); _ck(_v, 6, 0, currVal_2, currVal_3); }); }
export function View_HistoryPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "history-panel", [], null, null, null, View_HistoryPanelComponent_0, RenderType_HistoryPanelComponent)), i1.ɵdid(1, 49152, null, 0, i17.HistoryPanelComponent, [i18.HistoryToolService, i19.OverlayPanelRef], null, null)], null, null); }
var HistoryPanelComponentNgFactory = i1.ɵccf("history-panel", i17.HistoryPanelComponent, View_HistoryPanelComponent_Host_0, {}, {}, []);
export { HistoryPanelComponentNgFactory as HistoryPanelComponentNgFactory };
