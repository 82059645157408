var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { fabric } from 'fabric';
import { Settings } from 'common/core/config/settings.service';
import { ActiveFrameService } from './active-frame.service';
import { CanvasService } from '../../canvas/canvas.service';
import { staticObjectConfig } from '../../objects/static-object-config';
var FramePatternsService = /** @class */ (function () {
    function FramePatternsService(config, activeFrame, canvas) {
        this.config = config;
        this.activeFrame = activeFrame;
        this.canvas = canvas;
    }
    /**
     * Fill frame part objects with matching pattern images.
     */
    FramePatternsService.prototype.fillParts = function (mode) {
        var _this = this;
        this.patternCache.forEach(function (part) {
            _this.fillPartWithPattern(part, mode);
        });
    };
    /**
     * Fill specified frame part with matching pattern.
     */
    FramePatternsService.prototype.fillPartWithPattern = function (part, mode) {
        var patternSourceCanvas = new fabric.StaticCanvas();
        patternSourceCanvas.add(part.img);
        var pattern = new fabric.Pattern({
            source: function () {
                var width = part.img.getScaledWidth(), height = part.img.getScaledHeight();
                if (width > 1 && height > 1) {
                    patternSourceCanvas.setDimensions({ width: width, height: height });
                    patternSourceCanvas.renderAll();
                }
                return patternSourceCanvas.getElement();
            },
            repeat: mode === 'repeat' ? 'repeat' : 'no-repeat'
        });
        this.activeFrame[part.name].set('fill', pattern);
    };
    /**
     * Scale all frame patterns to fill their container rect objects.
     */
    FramePatternsService.prototype.scale = function (value) {
        var _this = this;
        var mode = this.activeFrame.config.mode;
        if (!this.patternCache)
            return;
        value = value / this.canvas.fabric().getRetinaScaling();
        this.patternCache.forEach(function (part) {
            // scale or repeat top and bottom sides
            if (part.name === 'top' || part.name === 'bottom') {
                if (mode === 'stretch') {
                    _this.scalePatternToWidth(part.img, _this.canvas.state.original.width - (value * 2) + 4); // minus width of left and right corners
                    _this.scalePatternToHeight(part.img, value);
                }
                else {
                    part.img.scaleToHeight(value);
                }
            }
            // scale or repeat left and right sides
            else if (part.name === 'left' || part.name === 'right') {
                if (mode === 'stretch') {
                    _this.scalePatternToWidth(part.img, value);
                    _this.scalePatternToHeight(part.img, _this.canvas.state.original.height - (value * 2) + 4); // minus width of left and right corners
                }
                else {
                    part.img.scaleToWidth(value);
                }
            }
            // scale corners
            else {
                part.img.scaleToWidth(value);
            }
        });
    };
    /**
     * Scale pattern image to specified width.
     */
    FramePatternsService.prototype.scalePatternToWidth = function (pattern, value) {
        var boundingRectFactor = pattern.getBoundingRect().width / pattern.getScaledWidth();
        pattern.set('scaleX', value / pattern.width / boundingRectFactor);
        pattern.setCoords();
    };
    /**
     * Scale pattern image to specified height.
     */
    FramePatternsService.prototype.scalePatternToHeight = function (pattern, value) {
        var boundingRectFactor = pattern.getBoundingRect().height / pattern.getScaledHeight();
        pattern.set('scaleY', value / pattern.height / boundingRectFactor);
        pattern.setCoords();
    };
    /**
     * Load all images needed to build specified frame.
     */
    FramePatternsService.prototype.load = function (frame) {
        var _this = this;
        var promises = this.activeFrame.getPartNames().map(function (part) {
            return new Promise(function (resolve) {
                var img = new Image();
                img.onload = function () {
                    var config = __assign({}, staticObjectConfig);
                    if (_this.config.get('pixie.crossOrigin')) {
                        config.crossOrigin = 'anonymous';
                    }
                    resolve({ name: part, img: new fabric.Image(img, config) });
                };
                img.src = _this.getPartUrl(frame, part);
            });
        });
        return Promise.all(promises).then(function (images) {
            _this.patternCache = images;
            _this.fillParts(frame.mode);
        });
    };
    FramePatternsService.prototype.getPartUrl = function (frame, part) {
        return this.getBaseUrl(frame) + '/' + part + '.png';
    };
    FramePatternsService.prototype.getBaseUrl = function (frame) {
        return this.config.getAssetUrl('images/frames/' + frame.name);
    };
    return FramePatternsService;
}());
export { FramePatternsService };
