var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Toast } from '../../ui/toast.service';
import { Translations } from '../../translations/translations.service';
import { HttpErrorHandler } from './http-error-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "../../translations/translations.service";
import * as i2 from "../../ui/toast.service";
var ClientHttpErrorHandler = /** @class */ (function (_super) {
    __extends(ClientHttpErrorHandler, _super);
    /**
     * HttpErrorHandler Constructor.
     */
    function ClientHttpErrorHandler(i18n, toast) {
        var _this = _super.call(this, i18n) || this;
        _this.i18n = i18n;
        _this.toast = toast;
        return _this;
    }
    /**
     *  403 errors won't happen in client only apps,
     *  so we can stub this method.
     */
    ClientHttpErrorHandler.prototype.handle403Error = function (response) { };
    ClientHttpErrorHandler.ngInjectableDef = i0.defineInjectable({ factory: function ClientHttpErrorHandler_Factory() { return new ClientHttpErrorHandler(i0.inject(i1.Translations), i0.inject(i2.Toast)); }, token: ClientHttpErrorHandler, providedIn: "root" });
    return ClientHttpErrorHandler;
}(HttpErrorHandler));
export { ClientHttpErrorHandler };
