/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filter-drawer.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "@angular/common";
import * as i10 from "../../../../../common/core/translations/translate.directive";
import * as i11 from "../../../../../common/core/translations/translations.service";
import * as i12 from "../../../../../common/core/config/settings.service";
import * as i13 from "../filter-controls/filter-controls.component.ngfactory";
import * as i14 from "../filter-controls/filter-controls.component";
import * as i15 from "../../../../image-editor/tools/filter/filter-tool.service";
import * as i16 from "@ngxs/store";
import * as i17 from "./filter-drawer.component";
var styles_FilterDrawerComponent = [i0.styles];
var RenderType_FilterDrawerComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FilterDrawerComponent, data: {} });
export { RenderType_FilterDrawerComponent as RenderType_FilterDrawerComponent };
function View_FilterDrawerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.showFilterControls(_v.parent.parent.context.$implicit);
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "settings"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_4 = "settings"; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; var currVal_3 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_2, currVal_3); }); }
function View_FilterDrawerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.removeFilter(_v.parent.context.$implicit);
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "cancel"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterDrawerComponent_4)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = "cancel"; _ck(_v, 4, 0, currVal_4); var currVal_5 = _co.filterTool.hasOptions(_v.parent.context.$implicit); _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 4).inline; var currVal_3 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_FilterDrawerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "button-with-image"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.applyFilter(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "Filter preview"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterDrawerComponent_3)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "bottom-label"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(6, 4341760, null, 0, i10.TranslateDirective, [i1.ElementRef, i11.Translations, i12.Settings], null, null), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var currVal_1 = (_v.parent.context.ngIf.indexOf(_v.context.$implicit) > (0 - 1)); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getFilterImage(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.getFilterDisplayName(_v.context.$implicit); _ck(_v, 7, 0, currVal_2); }); }
function View_FilterDrawerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "content filter-list"]], [[2, "hidden", null]], null, null, null, null)), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterDrawerComponent_2)), i1.ɵdid(3, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.filterList; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.selectedFilter$)); _ck(_v, 0, 0, currVal_0); }); }
function View_FilterDrawerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "filter-controls", [["class", "content"]], null, null, null, i13.View_FilterControlsComponent_0, i13.RenderType_FilterControlsComponent)), i1.ɵdid(1, 278528, null, 0, i9.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(2, 49152, null, 0, i14.FilterControlsComponent, [i15.FilterToolService, i16.Store], null, null)], function (_ck, _v) { var currVal_0 = "content"; var currVal_1 = _v.context.ngIf; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_FilterDrawerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_FilterDrawerComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FilterDrawerComponent_5)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.activeFilters$)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.selectedFilter$)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_FilterDrawerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "filter-drawer", [["class", "controls-drawer"]], null, null, null, View_FilterDrawerComponent_0, RenderType_FilterDrawerComponent)), i1.ɵdid(1, 49152, null, 0, i17.FilterDrawerComponent, [i12.Settings, i15.FilterToolService, i16.Store, i12.Settings], null, null)], null, null); }
var FilterDrawerComponentNgFactory = i1.ɵccf("filter-drawer", i17.FilterDrawerComponent, View_FilterDrawerComponent_Host_0, {}, {}, []);
export { FilterDrawerComponentNgFactory as FilterDrawerComponentNgFactory };
