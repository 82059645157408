var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Action, Actions, Selector, State, Store } from '@ngxs/store';
import { HistoryToolService } from '../../../image-editor/history/history-tool.service';
import { CloseForePanel } from '../../../image-editor/state/editor-state-actions';
import { HistoryNames } from '../../../image-editor/history/history-names.enum';
import { BaseToolState } from '../base-tool.state';
import { DrawerName } from '../../toolbar-controls/drawers/drawer-name.enum';
import { ResizeToolService } from '../../../image-editor/tools/resize/resize-tool.service';
import { Toast } from '../../../../common/core/ui/toast.service';
import { SetResizeDimensions } from './resize.actions';
var RESIZE_STATE_DEFAULTS = {
    dirty: false,
    resized: false,
    width: null,
    height: null,
    usePercentages: false,
};
var ResizeState = /** @class */ (function (_super) {
    __extends(ResizeState, _super);
    function ResizeState(store, history, actions$, resizeTool, toast) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this.history = history;
        _this.actions$ = actions$;
        _this.resizeTool = resizeTool;
        _this.toast = toast;
        _this.toolName = DrawerName.RESIZE;
        return _this;
    }
    ResizeState.dirty = function (state) {
        return state.dirty;
    };
    ResizeState.prototype.setResizeDimensions = function (ctx, action) {
        ctx.patchState(__assign({}, action.params, { dirty: true }));
    };
    ResizeState.prototype.applyChanges = function (ctx) {
        var _a = ctx.getState(), width = _a.width, height = _a.height, usePercentages = _a.usePercentages;
        this.store.dispatch(new CloseForePanel());
        this.resizeTool.apply(width, height, usePercentages);
        this.toast.open('Photo resized.');
        if (ctx.getState().dirty) {
            this.history.add(HistoryNames.RESIZE);
        }
        ctx.patchState(RESIZE_STATE_DEFAULTS);
    };
    ResizeState.prototype.cancelChanges = function (ctx) {
        this.store.dispatch(new CloseForePanel());
        ctx.patchState(RESIZE_STATE_DEFAULTS);
    };
    __decorate([
        Action(SetResizeDimensions),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetResizeDimensions]),
        __metadata("design:returntype", void 0)
    ], ResizeState.prototype, "setResizeDimensions", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ResizeState, "dirty", null);
    ResizeState = __decorate([
        State({
            name: 'resize',
            defaults: RESIZE_STATE_DEFAULTS
        }),
        __metadata("design:paramtypes", [Store,
            HistoryToolService,
            Actions,
            ResizeToolService,
            Toast])
    ], ResizeState);
    return ResizeState;
}(BaseToolState));
export { ResizeState };
