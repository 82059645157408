var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Settings } from 'common/core/config/settings.service';
import { Select, Store } from '@ngxs/store';
import { ShapesState } from '../../../state/shapes/shapes.state';
import { Observable } from 'rxjs';
import { AddShape } from '../../../state/shapes/shapes.actions';
var ShapesDrawerComponent = /** @class */ (function () {
    function ShapesDrawerComponent(config, store) {
        this.config = config;
        this.store = store;
        this.shapes = this.config.get('pixie.tools.shapes.items');
    }
    ShapesDrawerComponent.prototype.addShape = function (shape) {
        this.store.dispatch(new AddShape(shape));
    };
    __decorate([
        Select(ShapesState.dirty),
        __metadata("design:type", Observable)
    ], ShapesDrawerComponent.prototype, "dirty$", void 0);
    return ShapesDrawerComponent;
}());
export { ShapesDrawerComponent };
