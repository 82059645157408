var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Action, Actions, Selector, State, Store } from '@ngxs/store';
import { HistoryToolService } from '../../../image-editor/history/history-tool.service';
import { CloseForePanel } from '../../../image-editor/state/editor-state-actions';
import { HistoryNames } from '../../../image-editor/history/history-names.enum';
import { BaseToolState } from '../base-tool.state';
import { DrawerName } from '../../toolbar-controls/drawers/drawer-name.enum';
import { CloseBrushControls, MarkAsDirty, OpenBrushControls } from './draw.actions';
var DRAW_STATE_DEFAULTS = {
    dirty: false,
    brushControlsOpen: false,
};
var DrawState = /** @class */ (function (_super) {
    __extends(DrawState, _super);
    function DrawState(store, history, actions$) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this.history = history;
        _this.actions$ = actions$;
        _this.toolName = DrawerName.DRAW;
        return _this;
    }
    DrawState.dirty = function (state) {
        return state.dirty;
    };
    DrawState.brushControlsOpen = function (state) {
        return state.brushControlsOpen;
    };
    DrawState.prototype.markAsDirty = function (ctx) {
        ctx.patchState({ dirty: true });
    };
    DrawState.prototype.openBrushControls = function (ctx) {
        ctx.patchState({ brushControlsOpen: true });
    };
    DrawState.prototype.closeBrushControls = function (ctx) {
        ctx.patchState({ brushControlsOpen: false });
    };
    DrawState.prototype.applyChanges = function (ctx) {
        this.store.dispatch(new CloseForePanel());
        if (ctx.getState().dirty) {
            this.history.add(HistoryNames.DRAW);
        }
        ctx.patchState(DRAW_STATE_DEFAULTS);
    };
    DrawState.prototype.cancelChanges = function (ctx) {
        if (!ctx.getState().brushControlsOpen) {
            this.store.dispatch(new CloseForePanel());
        }
        if (ctx.getState().dirty) {
            this.history.reload();
        }
        ctx.patchState(DRAW_STATE_DEFAULTS);
    };
    __decorate([
        Action(MarkAsDirty),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DrawState.prototype, "markAsDirty", null);
    __decorate([
        Action(OpenBrushControls),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DrawState.prototype, "openBrushControls", null);
    __decorate([
        Action(CloseBrushControls),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DrawState.prototype, "closeBrushControls", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DrawState, "dirty", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DrawState, "brushControlsOpen", null);
    DrawState = __decorate([
        State({
            name: 'draw',
            defaults: DRAW_STATE_DEFAULTS
        }),
        __metadata("design:paramtypes", [Store,
            HistoryToolService,
            Actions])
    ], DrawState);
    return DrawState;
}(BaseToolState));
export { DrawState };
