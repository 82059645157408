var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PIXIE_VERSION } from './default-settings';
import { environment } from '../../environments/environment';
export function init_app(settings, mergedConfig) {
    return function () {
        var baseConfig = {
            base_url: mergedConfig.baseUrl,
            version: PIXIE_VERSION,
            logging: {
                sentry_public: mergedConfig.sentry_public,
            },
            vebto: {
                environment: environment.production ? 'production' : 'dev',
            }
        };
        settings.setMultiple(__assign({}, baseConfig, { pixie: mergedConfig }), true);
        return new Promise(function (resolve) { return resolve(); });
    };
}
var ImageEditorModule = /** @class */ (function () {
    function ImageEditorModule() {
    }
    return ImageEditorModule;
}());
export { ImageEditorModule };
