var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EditorControlsService } from './editor-controls.service';
import { Settings } from 'common/core/config/settings.service';
import { ActiveObjectService } from '../../image-editor/canvas/active-object/active-object.service';
import { Select, Store } from '@ngxs/store';
import { EditorState } from '../../image-editor/state/editor-state';
import { Observable } from 'rxjs';
import { ApplyChanges, CancelChanges } from '../../image-editor/state/editor-state-actions';
import { BreakpointsService } from '../../../common/core/ui/breakpoints.service';
var EditorControlsComponent = /** @class */ (function () {
    function EditorControlsComponent(controls, settings, activeObject, store, breakpoints) {
        this.controls = controls;
        this.settings = settings;
        this.activeObject = activeObject;
        this.store = store;
        this.breakpoints = breakpoints;
    }
    EditorControlsComponent.prototype.applyChanges = function () {
        var panel = this.store.selectSnapshot(EditorState.activePanel);
        this.store.dispatch(new ApplyChanges(panel));
    };
    EditorControlsComponent.prototype.cancelChanges = function () {
        var panel = this.store.selectSnapshot(EditorState.activePanel);
        this.store.dispatch(new CancelChanges(panel));
    };
    __decorate([
        Select(EditorState.activePanel),
        __metadata("design:type", Observable)
    ], EditorControlsComponent.prototype, "activePanel$", void 0);
    __decorate([
        Select(EditorState.controlsPosition),
        __metadata("design:type", Observable)
    ], EditorControlsComponent.prototype, "controlsPosition$", void 0);
    __decorate([
        Select(EditorState.activeObjId),
        __metadata("design:type", Observable)
    ], EditorControlsComponent.prototype, "activeObjId$", void 0);
    __decorate([
        Select(EditorState.dirty),
        __metadata("design:type", Observable)
    ], EditorControlsComponent.prototype, "dirty$", void 0);
    return EditorControlsComponent;
}());
export { EditorControlsComponent };
