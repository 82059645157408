var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { fabric } from 'fabric';
import { CanvasService } from '../../canvas/canvas.service';
import { ActiveObjectService } from '../../canvas/active-object/active-object.service';
import { ObjectsService } from '../../objects/objects.service';
import { ObjectNames } from '../../objects/object-names.enum';
import { Settings } from '../../../../common/core/config/settings.service';
import { normalizeObjectProps } from '../../utils/normalize-object-props';
import { FontsPaginatorService } from '../../../image-editor-ui/toolbar-controls/widgets/google-fonts-panel/fonts-paginator.service';
var TextToolService = /** @class */ (function () {
    function TextToolService(canvas, activeObject, objects, config, fontsPaginator) {
        this.canvas = canvas;
        this.activeObject = activeObject;
        this.objects = objects;
        this.config = config;
        this.fontsPaginator = fontsPaginator;
        this.minWidth = 250;
    }
    TextToolService.prototype.add = function (text, providedConfig) {
        if (text === void 0) { text = null; }
        if (providedConfig === void 0) { providedConfig = {}; }
        text = text || this.config.get('pixie.tools.text.defaultText');
        var options = __assign({}, normalizeObjectProps(this.activeObject.form.value), providedConfig, { name: ObjectNames.text.name });
        var itext = new fabric.IText(text, options);
        this.canvas.fabric().add(itext);
        this.autoPositionText(itext);
        this.canvas.fabric().setActiveObject(itext);
        this.canvas.render();
    };
    TextToolService.prototype.autoPositionText = function (text) {
        var _this = this;
        var canvasWidth = this.canvas.fabric().getWidth(), canvasHeight = this.canvas.fabric().getHeight();
        // make sure min width is not larger then canvas width
        var minWidth = Math.min(this.canvas.fabric().getWidth(), this.minWidth);
        text.scaleToWidth(Math.max(canvasWidth / 3, minWidth));
        // make sure text is not scaled outside canvas
        if (text.getScaledHeight() > canvasHeight) {
            text.scaleToHeight(canvasHeight - text.getScaledHeight() - 20);
        }
        text.viewportCenter();
        // push text down, if it intersects with another text object
        this.canvas.fabric().getObjects('i-text').forEach(function (obj) {
            if (obj === text)
                return;
            if (obj.intersectsWithObject(text)) {
                var offset = (obj.top - text.top) + obj.getScaledHeight();
                var newTop = text.top + offset;
                // if pushing object down would push it outside canvas, position text at top of canvas
                if (newTop > _this.canvas.state.original.height - obj.getScaledHeight()) {
                    newTop = 0;
                }
                text.set('top', newTop);
                text.setCoords();
            }
        });
    };
    /**
     * Get all custom fonts used on canvas.
     */
    TextToolService.prototype.getUsedFonts = function () {
        var _this = this;
        return this.objects.getAll()
            .filter(function (obj) { return obj.type === 'i-text'; })
            .map(function (obj) {
            var family = obj.fontFamily;
            return _this.fontsPaginator.original.find(function (f) { return f.family === family; });
        }).filter(function (font) {
            return font && font.type !== 'basic';
        });
    };
    return TextToolService;
}());
export { TextToolService };
