/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./crop-drawer.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../common/core/translations/translate.directive";
import * as i3 from "../../../../../common/core/translations/translations.service";
import * as i4 from "../../../../../common/core/config/settings.service";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/common";
import * as i7 from "./crop-drawer.component";
import * as i8 from "../../../../image-editor/tools/crop/crop-zone.service";
var styles_CropDrawerComponent = [i0.styles];
var RenderType_CropDrawerComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CropDrawerComponent, data: {} });
export { RenderType_CropDrawerComponent as RenderType_CropDrawerComponent };
function View_CropDrawerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "custom-ratio-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "inputs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "input-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "label", [["for", "width"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(4, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Width"])), (_l()(), i1.ɵeld(6, 0, null, null, 6, "input", [["id", "width"], ["min", "1"], ["type", "number"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i1.ɵnov(_v, 8).onChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (i1.ɵnov(_v, 8).onChange($event.target.value) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i1.ɵnov(_v, 8).onTouched() !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.cropzoneWidth = $event) !== false);
        ad = (pd_7 && ad);
    } if (("ngModelChange" === en)) {
        var pd_8 = (_co.resizeCropzone() !== false);
        ad = (pd_8 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(8, 16384, null, 0, i5.ɵangular_packages_forms_forms_be, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i5.DefaultValueAccessor, i5.ɵangular_packages_forms_forms_be]), i1.ɵdid(10, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(12, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 10, "div", [["class", "input-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "label", [["for", "height"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(15, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Height"])), (_l()(), i1.ɵeld(17, 0, null, null, 6, "input", [["id", "height"], ["min", "1"], ["type", "number"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 18).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 18)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 18)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i1.ɵnov(_v, 19).onChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (i1.ɵnov(_v, 19).onChange($event.target.value) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i1.ɵnov(_v, 19).onTouched() !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.cropzoneHeight = $event) !== false);
        ad = (pd_7 && ad);
    } if (("ngModelChange" === en)) {
        var pd_8 = (_co.resizeCropzone() !== false);
        ad = (pd_8 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(19, 16384, null, 0, i5.ɵangular_packages_forms_forms_be, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i5.DefaultValueAccessor, i5.ɵangular_packages_forms_forms_be]), i1.ɵdid(21, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(23, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.cropzoneWidth; _ck(_v, 10, 0, currVal_7); var currVal_15 = _co.cropzoneHeight; _ck(_v, 21, 0, currVal_15); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 12).ngClassValid; var currVal_5 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 23).ngClassUntouched; var currVal_9 = i1.ɵnov(_v, 23).ngClassTouched; var currVal_10 = i1.ɵnov(_v, 23).ngClassPristine; var currVal_11 = i1.ɵnov(_v, 23).ngClassDirty; var currVal_12 = i1.ɵnov(_v, 23).ngClassValid; var currVal_13 = i1.ɵnov(_v, 23).ngClassInvalid; var currVal_14 = i1.ɵnov(_v, 23).ngClassPending; _ck(_v, 17, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }); }
function View_CropDrawerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "aspect-ratio"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.applyAspectRatio(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["ratioPreview", 1]], null, 0, "div", [["class", "preview"]], [[1, "data-ratio", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "name"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(3, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.cropZone.aspectRatio === _v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 4, 0, currVal_2); }); }
export function View_CropDrawerComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { ratioPreviews: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "aspect-ratios"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CropDrawerComponent_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CropDrawerComponent_2)), i1.ɵdid(6, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.config.get("pixie.tools.crop.hideCustomControls"); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.aspectRatios; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_CropDrawerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "crop-drawer", [["class", "controls-drawer"]], null, null, null, View_CropDrawerComponent_0, RenderType_CropDrawerComponent)), i1.ɵdid(1, 4440064, null, 0, i7.CropDrawerComponent, [i8.CropZoneService, i4.Settings], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CropDrawerComponentNgFactory = i1.ɵccf("crop-drawer", i7.CropDrawerComponent, View_CropDrawerComponent_Host_0, {}, {}, []);
export { CropDrawerComponentNgFactory as CropDrawerComponentNgFactory };
