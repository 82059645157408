import { ElementRef, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { EditorState } from './image-editor/state/editor-state';
import { EditorTheme } from './image-editor/enums/editor-theme.enum';
import { EditorMode } from './image-editor/enums/editor-mode.enum';
import { Settings } from '../common/core/config/settings.service';
import { CloseEditor } from './image-editor/state/editor-state-actions';
var AppComponent = /** @class */ (function () {
    function AppComponent(el, store, config) {
        this.el = el;
        this.store = store;
        this.config = config;
    }
    Object.defineProperty(AppComponent.prototype, "animate", {
        get: function () {
            return this.store.selectSnapshot(EditorState.visible);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "darkTheme", {
        get: function () {
            return this.store.selectSnapshot(EditorState.theme) === EditorTheme.DARK;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "lightTheme", {
        get: function () {
            return this.store.selectSnapshot(EditorState.theme) === EditorTheme.LIGHT;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "overlayMode", {
        get: function () {
            return this.store.selectSnapshot(EditorState.mode) === EditorMode.OVERLAY;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "inlineMode", {
        get: function () {
            return this.store.selectSnapshot(EditorState.mode) === EditorMode.INLINE;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "width", {
        get: function () {
            return this.config.get('pixie.ui.width');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "height", {
        get: function () {
            return this.config.get('pixie.ui.height');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "compact", {
        get: function () {
            return this.config.get('pixie.ui.compact');
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.ngOnInit = function () {
        this.bindToOverlayClick(this.overlay);
    };
    AppComponent.prototype.bindToOverlayClick = function (overlay) {
        var _this = this;
        overlay.nativeElement.addEventListener('click', function () {
            _this.store.dispatch(new CloseEditor());
        });
    };
    return AppComponent;
}());
export { AppComponent };
