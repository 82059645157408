var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { HistoryToolService } from '../../image-editor/history/history-tool.service';
import { ActiveObjectService } from '../../image-editor/canvas/active-object/active-object.service';
import { EditorControlsService } from '../toolbar-controls/editor-controls.service';
import { ObjectsService } from '../../image-editor/objects/objects.service';
import { BreakpointsService } from '../../../common/core/ui/breakpoints.service';
import { Select, Store } from '@ngxs/store';
import { HistoryState } from '../state/history/history.state';
import { DrawerName } from '../toolbar-controls/drawers/drawer-name.enum';
import { EditorState } from '../../image-editor/state/editor-state';
import { Observable } from 'rxjs';
import { ObjectNames } from '../../image-editor/objects/object-names.enum';
import { OpenPanel } from '../../image-editor/state/editor-state-actions';
var FloatingObjectControlsComponent = /** @class */ (function () {
    function FloatingObjectControlsComponent(history, activeObject, controls, breakpoints, objects, store) {
        this.history = history;
        this.activeObject = activeObject;
        this.controls = controls;
        this.breakpoints = breakpoints;
        this.objects = objects;
        this.store = store;
    }
    FloatingObjectControlsComponent.prototype.deleteObject = function () {
        var obj = this.activeObject.get();
        if (!obj)
            return;
        this.activeObject.delete();
        this.history.add({ name: "Deleted: " + obj.name, icon: 'delete-custom' });
    };
    /**
     * Open drawer for adding more of current active object.
     */
    FloatingObjectControlsComponent.prototype.openObjectDrawer = function () {
        switch (this.activeObject.get().name) {
            case ObjectNames.shape.name:
                this.controls.openPanel(DrawerName.SHAPES);
                break;
            case ObjectNames.sticker.name:
                this.controls.openPanel(DrawerName.STICKERS);
                break;
            case ObjectNames.text.name:
                this.controls.openPanel(DrawerName.TEXT);
                break;
        }
    };
    FloatingObjectControlsComponent.prototype.openObjSettingsPanel = function () {
        this.store.dispatch(new OpenPanel(DrawerName.OBJECT_SETTINGS));
    };
    FloatingObjectControlsComponent.prototype.bringActiveObjectToFront = function () {
        this.activeObject.bringToFront();
        this.objects.syncObjects();
    };
    __decorate([
        Select(HistoryState.canUndo),
        __metadata("design:type", Observable)
    ], FloatingObjectControlsComponent.prototype, "canUndo$", void 0);
    __decorate([
        Select(HistoryState.canRedo),
        __metadata("design:type", Observable)
    ], FloatingObjectControlsComponent.prototype, "canRedo$", void 0);
    __decorate([
        Select(EditorState.activeObjId),
        __metadata("design:type", Observable)
    ], FloatingObjectControlsComponent.prototype, "activeObjId$", void 0);
    __decorate([
        Select(EditorState.dirty),
        __metadata("design:type", Observable)
    ], FloatingObjectControlsComponent.prototype, "dirty$", void 0);
    return FloatingObjectControlsComponent;
}());
export { FloatingObjectControlsComponent };
