var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Settings } from 'common/core/config/settings.service';
import { ShapesToolService } from '../../../../image-editor/tools/shapes/shapes-tool.service';
import { Select, Store } from '@ngxs/store';
import { MarkAsDirty, OpenStickerCategory } from '../../../state/stickers/stickers.actions';
import { StickersState } from '../../../state/stickers/stickers.state';
import { Observable } from 'rxjs';
var StickersDrawerComponent = /** @class */ (function () {
    function StickersDrawerComponent(shapesTool, config, store) {
        this.shapesTool = shapesTool;
        this.config = config;
        this.store = store;
        this.categories = this.config.get('pixie.tools.stickers.items');
    }
    StickersDrawerComponent.prototype.addSticker = function (category, name) {
        var _this = this;
        this.shapesTool.addSticker(category, name).then(function () {
            _this.store.dispatch(new MarkAsDirty());
        });
    };
    StickersDrawerComponent.prototype.openStickersCategory = function (category) {
        this.store.dispatch(new OpenStickerCategory(category));
    };
    /**
     * Get iterable for specified categories items.
     */
    StickersDrawerComponent.prototype.getStickersIterable = function (category) {
        if (category.list)
            return category.list;
        return Array.from(Array(category.items).keys());
    };
    __decorate([
        Select(StickersState.activeCategory),
        __metadata("design:type", Observable)
    ], StickersDrawerComponent.prototype, "activeCategory$", void 0);
    return StickersDrawerComponent;
}());
export { StickersDrawerComponent };
