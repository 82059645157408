var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { fabric } from 'fabric';
import { CanvasService } from '../../canvas/canvas.service';
import { Subject } from 'rxjs';
import { CanvasStateService } from '../../canvas/canvas-state.service';
import { Settings } from '../../../../common/core/config/settings.service';
var CropZoneService = /** @class */ (function () {
    function CropZoneService(canvas, canvasState, config) {
        this.canvas = canvas;
        this.canvasState = canvasState;
        this.config = config;
        /**
         * Whether cropzone is initiated already.
         */
        this.initiated = false;
        /**
         * Color for grid lines and borders.
         */
        this.gridColor = 'rgba(244,244,244,.4)';
        /**
         * Current cropzone aspect ratio.
         */
        this.aspectRatio = '16:9';
        /**
         * Fired whenever cropzone size changes.
         */
        this.resize$ = new Subject();
    }
    CropZoneService.prototype.draw = function () {
        this.drawMainZone();
        this.drawOverlay();
        this.drawGrid();
        this.attachEvents();
        this.canvas.render();
        this.initiated = true;
    };
    /**
     * Remove cropzone from canvas.
     */
    CropZoneService.prototype.remove = function () {
        if (!this.initiated)
            return;
        this.rect.off();
        this.canvas.fabric().discardActiveObject();
        this.canvas.fabric().remove(this.rect, this.grid, this.overlay);
        this.canvas.render();
        this.initiated = false;
    };
    /**
     * Prevent cropzone from going outside canvas edges during dragging.
     */
    CropZoneService.prototype.constrainWithinCanvas = function (object) {
        var x = object.left, y = object.top;
        var w = object.getScaledWidth(), h = object.getScaledHeight();
        var maxX = this.canvasState.original.width - w;
        var maxY = this.canvasState.original.height - h;
        if (x < 0) {
            object.left = 0;
        }
        if (y < 0) {
            object.top = 0;
        }
        if (x > maxX) {
            object.left = maxX;
        }
        if (y > maxY) {
            object.top = maxY;
        }
    };
    /**
     * Prevent cropzone from going outside canvas edges during scaling.
     */
    CropZoneService.prototype.constrainWithinCanvasOnScaling = function (object) {
        var minX = Math.floor(object.left);
        var minY = Math.floor(object.top);
        //allow 1px deviance
        var maxX = minX + object.getScaledWidth() - 1;
        var maxY = minY + object.getScaledHeight() - 1;
        if (minX < 0 || maxX > this.canvasState.original.width) {
            var lastScaleX = this.lastScaleX || 1;
            object.scaleX = lastScaleX;
        }
        if (minX < 0) {
            object.left = 0;
        }
        if (minY < 0 || maxY > this.canvasState.original.height) {
            var lastScaleY = this.lastScaleY || 1;
            object.scaleY = lastScaleY;
        }
        if (minY < 0) {
            object.top = 0;
        }
        this.lastScaleX = object.scaleX;
        this.lastScaleY = object.scaleY;
    };
    CropZoneService.prototype.attachEvents = function () {
        var _this = this;
        //redraw cropzone grid and overlay when cropzone is resized
        this.rect.on('moving', function () {
            _this.constrainWithinCanvas(_this.rect);
            _this.drawOverlay();
            _this.drawGrid();
            _this.resize$.next();
        });
        this.rect.on('scaling', function () {
            _this.constrainWithinCanvasOnScaling(_this.rect);
            _this.drawOverlay();
            _this.drawGrid();
            _this.resize$.next();
        });
    };
    CropZoneService.prototype.gcd = function (a, b) {
        return (b == 0) ? a : this.gcd(b, a % b);
    };
    /**
     * Calculate cropzone size and position based on specified aspect ratio and canvas size.
     */
    CropZoneService.prototype.getAdjustedSize = function (ratioString, oldWidth, oldHeight) {
        if (ratioString === void 0) { ratioString = '16:9'; }
        var newWidth = oldWidth, newHeight = oldHeight;
        //convert ratio string to number
        var parts = ratioString.split(':');
        var aspectRatio = parseInt(parts[0]) / parseInt(parts[1]);
        //calculate cropzone with and height based on aspect ratio and canvas size
        if (oldHeight * aspectRatio > oldWidth) {
            newHeight = oldWidth / aspectRatio;
        }
        else {
            newWidth = oldHeight * aspectRatio;
        }
        return { width: newWidth, height: newHeight };
    };
    CropZoneService.prototype.drawMainZone = function (aspectRatio) {
        var _this = this;
        if (!this.initiated) {
            var defaultConfig = {
                fill: 'transparent',
                borderColor: this.gridColor,
                borderScaleFactor: 2,
                hasRotatingPoint: false,
                name: 'crop.zone',
                lockScalingFlip: true,
                minScaleLimit: 0,
                hasControls: !this.config.get('pixie.tools.crop.hideCustomControls'),
            };
            this.rect = new fabric.Rect(__assign({}, defaultConfig, this.config.get('pixie.tools.crop.cropzone', {})));
            //prevent deselection of crop zone
            this.rect.on('deselected', function () {
                _this.canvas.fabric().setActiveObject(_this.rect);
            });
            this.canvas.fabric().add(this.rect);
            this.rect.moveTo(3);
            this.canvas.fabric().setActiveObject(this.rect);
        }
        var dimensions = this.getAdjustedSize(aspectRatio, this.canvasState.original.width, this.canvasState.original.height);
        this.rect.set({
            width: dimensions.width,
            height: dimensions.height,
            lockUniScaling: !!aspectRatio,
        });
        this.rect.viewportCenter();
        this.rect.setCoords();
    };
    CropZoneService.prototype.drawGrid = function () {
        if (!this.initiated) {
            var lineOptions = { stroke: this.gridColor, strokeWidth: 4, selectable: false, evented: false };
            var lines_1 = [
                new fabric.Line([], lineOptions),
                new fabric.Line([], lineOptions),
                new fabric.Line([], lineOptions),
                new fabric.Line([], lineOptions),
            ];
            this.grid = new fabric.Group(lines_1, {
                name: 'crop.grid',
                selectable: false,
                evented: false,
            });
            this.canvas.fabric().add(this.grid);
            this.grid.moveTo(10);
        }
        //update grid group size and position
        this.grid.width = this.rect.getScaledWidth();
        this.grid.height = this.rect.getScaledHeight();
        this.grid.left = this.rect.left;
        this.grid.top = this.rect.top;
        //cache values needed for grid positioning
        var halfWidth = this.grid.width / 2;
        var thirdWidth = this.grid.width / 3;
        var halfHeight = this.grid.height / 2;
        var thirdHeight = this.grid.height / 3;
        //lines are positioned relative to group center
        //which means some confusing math is needed
        var lines = this.grid.getObjects();
        //first vertical
        lines[0].set({
            x1: -(halfWidth - thirdWidth),
            y1: -(halfHeight),
            x2: -(halfWidth - thirdWidth),
            y2: halfHeight,
        });
        //second vertical
        lines[1].set({
            x1: halfWidth - thirdWidth,
            y1: -(halfHeight),
            x2: halfWidth - thirdWidth,
            y2: halfHeight
        });
        //first horizontal
        lines[2].set({
            x1: -(halfWidth),
            y1: -(halfHeight - thirdHeight),
            x2: halfWidth,
            y2: -(halfHeight - thirdHeight)
        });
        //second horizontal
        lines[3].set({
            x1: -(halfWidth),
            y1: halfHeight - thirdHeight,
            x2: halfWidth,
            y2: halfHeight - thirdHeight
        });
    };
    CropZoneService.prototype.drawOverlay = function () {
        if (!this.initiated) {
            var topRect = new fabric.Rect({ fill: 'rgba(0,0,0,0.6)', selectable: true, evented: false });
            var rightRect = new fabric.Rect({ fill: 'rgba(0,0,0,0.6)', selectable: true, evented: false });
            var bottomRect = new fabric.Rect({ fill: 'rgba(0,0,0,0.6)', selectable: true, evented: false });
            var leftRect = new fabric.Rect({ fill: 'rgba(0,0,0,0.6)', selectable: true, evented: false });
            this.overlay = new fabric.Group([topRect, rightRect, bottomRect, leftRect], {
                width: this.canvasState.original.width,
                height: this.canvasState.original.height,
                selectable: false,
                evented: false,
                name: 'crop.overlay'
            });
            this.canvas.fabric().add(this.overlay);
            this.overlay.moveTo(1);
        }
        var groupLeftOffset = -(this.overlay.width / 2);
        var groupTopOffset = -(this.overlay.height / 2);
        var rects = this.overlay.getObjects();
        rects[0].set({
            left: groupLeftOffset,
            top: groupTopOffset,
            width: this.overlay.width,
            height: this.rect.getScaledHeight() < 0 ? this.rect.top - Math.abs(this.rect.getScaledHeight()) : this.rect.top,
        });
        rects[1].set({
            left: (this.rect.getScaledWidth() < 0 ? this.rect.left : this.rect.left + this.rect.getScaledWidth()) + groupLeftOffset,
            top: this.rect.top + groupTopOffset,
            width: this.rect.getScaledWidth() < 0 ? this.canvasState.original.width - (this.rect.left + this.rect.getScaledWidth()) - Math.abs(this.rect.getScaledWidth()) : this.canvasState.original.width - (this.rect.left + this.rect.getScaledWidth()),
            height: this.rect.getScaledHeight(),
        });
        rects[2].set({
            left: groupLeftOffset,
            top: (this.rect.getScaledHeight() < 0 ? this.rect.top : this.rect.top + this.rect.getScaledHeight()) + groupTopOffset,
            width: this.canvasState.original.width,
            height: this.rect.getScaledHeight() < 0 ? this.canvasState.original.height - (this.rect.top) : this.canvasState.original.height - (this.rect.top + this.rect.getScaledHeight()),
        });
        rects[3].set({
            left: groupLeftOffset,
            top: this.rect.top + groupTopOffset,
            width: this.rect.getScaledWidth() > 0 ? this.rect.left : this.rect.left - Math.abs(this.rect.getScaledWidth()),
            height: this.rect.getScaledHeight(),
        });
    };
    CropZoneService.prototype.changeAspectRatio = function (ratio) {
        this.aspectRatio = ratio;
        this.drawMainZone(ratio);
        this.drawOverlay();
        this.drawGrid();
        this.canvas.render();
    };
    CropZoneService.prototype.getSize = function () {
        return {
            width: this.rect.getScaledWidth(),
            height: this.rect.getScaledHeight(),
            left: this.rect.left,
            top: this.rect.top
        };
    };
    /**
     * Resize cropzone to specified dimensions.
     */
    CropZoneService.prototype.resize = function (width, height) {
        this.aspectRatio = null;
        this.rect.width = width;
        this.rect.height = height;
        this.rect.lockUniScaling = false;
        this.rect.setCoords();
        this.drawOverlay();
        this.drawGrid();
        this.canvas.render();
    };
    return CropZoneService;
}());
export { CropZoneService };
