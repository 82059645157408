var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Action, Actions, Selector, State, Store } from '@ngxs/store';
import { OpenFilterControls, RemoveFilter, ApplyFilter, SetAppliedFilters } from './filter.actions';
import { HistoryToolService } from '../../../image-editor/history/history-tool.service';
import { CloseForePanel } from '../../../image-editor/state/editor-state-actions';
import { HistoryNames } from '../../../image-editor/history/history-names.enum';
import { BaseToolState } from '../base-tool.state';
import { DrawerName } from '../../toolbar-controls/drawers/drawer-name.enum';
var FilterState = /** @class */ (function (_super) {
    __extends(FilterState, _super);
    function FilterState(store, history, actions$) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this.history = history;
        _this.actions$ = actions$;
        _this.toolName = DrawerName.FILTER;
        return _this;
    }
    FilterState.activeFilters = function (state) {
        return state.activeFilters;
    };
    FilterState.selectedFilter = function (state) {
        return state.selectedFilter;
    };
    FilterState.dirty = function (state) {
        return state.dirty;
    };
    FilterState.prototype.toggleFilter = function (ctx, action) {
        ctx.patchState({
            dirty: true,
            activeFilters: ctx.getState().activeFilters.concat([action.filter])
        });
    };
    FilterState.prototype.removeFilter = function (ctx, action) {
        var activeFilters = ctx.getState().activeFilters.filter(function (f) { return f !== action.filter; });
        ctx.patchState({ dirty: true, activeFilters: activeFilters });
    };
    FilterState.prototype.openFilterControls = function (ctx, action) {
        ctx.patchState({ selectedFilter: action.filter });
    };
    FilterState.prototype.setAppliedFilters = function (ctx, _a) {
        var filters = _a.filters;
        ctx.patchState({ activeFilters: filters });
    };
    FilterState.prototype.applyChanges = function (ctx) {
        this.store.dispatch(new CloseForePanel());
        if (ctx.getState().dirty) {
            this.history.add(HistoryNames.FILTER);
        }
        ctx.patchState({ dirty: false, selectedFilter: null });
    };
    FilterState.prototype.cancelChanges = function (ctx) {
        if (!ctx.getState().selectedFilter) {
            this.store.dispatch(new CloseForePanel());
        }
        if (ctx.getState().dirty) {
            this.history.reload();
        }
        ctx.patchState({ dirty: false, selectedFilter: null });
    };
    __decorate([
        Action(ApplyFilter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, ApplyFilter]),
        __metadata("design:returntype", void 0)
    ], FilterState.prototype, "toggleFilter", null);
    __decorate([
        Action(RemoveFilter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, RemoveFilter]),
        __metadata("design:returntype", void 0)
    ], FilterState.prototype, "removeFilter", null);
    __decorate([
        Action(OpenFilterControls),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, OpenFilterControls]),
        __metadata("design:returntype", void 0)
    ], FilterState.prototype, "openFilterControls", null);
    __decorate([
        Action(SetAppliedFilters),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetAppliedFilters]),
        __metadata("design:returntype", void 0)
    ], FilterState.prototype, "setAppliedFilters", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], FilterState, "activeFilters", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], FilterState, "selectedFilter", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], FilterState, "dirty", null);
    FilterState = __decorate([
        State({
            name: 'filter',
            defaults: {
                dirty: false,
                activeFilters: [],
                selectedFilter: null,
            }
        }),
        __metadata("design:paramtypes", [Store,
            HistoryToolService,
            Actions])
    ], FilterState);
    return FilterState;
}(BaseToolState));
export { FilterState };
