import { EventEmitter } from '@angular/core';
import * as Dot from 'dot-object';
import merge from 'deepmerge';
import * as i0 from "@angular/core";
var Settings = /** @class */ (function () {
    function Settings() {
        this.all = {};
        this.onChange = new EventEmitter();
    }
    /**
     * Set multiple settings on settings service.
     */
    Settings.prototype.setMultiple = function (settings, fireEvent) {
        if (fireEvent === void 0) { fireEvent = false; }
        for (var key in settings) {
            var value = settings[key];
            if (value === '0' || value === '1') {
                value = parseInt(value);
            }
            this.set(key, value);
        }
        if (fireEvent)
            this.onChange.emit();
    };
    Settings.prototype.merge = function (config, fireEvent) {
        if (fireEvent === void 0) { fireEvent = false; }
        this.all = merge(this.all, config);
        if (fireEvent)
            this.onChange.emit();
    };
    /**
     * Set single setting.
     */
    Settings.prototype.set = function (name, value, fireEvent) {
        if (fireEvent === void 0) { fireEvent = false; }
        Dot['set'](name, value, this.all);
        if (fireEvent)
            this.onChange.emit(name);
    };
    /**
     * Get a setting by key, optionally providing default value.
     */
    Settings.prototype.get = function (name, defaultValue) {
        if (defaultValue === void 0) { defaultValue = null; }
        var value = Dot.pick(name, this.all);
        if (value == null) {
            return defaultValue;
        }
        else {
            return value;
        }
    };
    /**
     * Get all settings.
     */
    Settings.prototype.getAll = function () {
        return this.all;
    };
    /**
     * Check if setting with specified name exists.
     */
    Settings.prototype.has = function (name) {
        return !!Dot.pick(name, this.all);
    };
    /**
     * Get a json setting by key and decode it.
     */
    Settings.prototype.getJson = function (name, defaultValue) {
        if (defaultValue === void 0) { defaultValue = null; }
        var value = this.get(name, defaultValue);
        if (typeof value !== 'string')
            return value;
        return JSON.parse(value);
    };
    /**
     * Get base url for the app.
     */
    Settings.prototype.getBaseUrl = function (forceServerUrl) {
        if (forceServerUrl === void 0) { forceServerUrl = false; }
        // sometimes we might need to get base url supplied by backend
        // even in development environment, for example, to prevent
        // uploaded images from having proxy urls like "localhost:4200"
        if (this.has('base_url') && (this.get('vebto.environment') === 'production' || forceServerUrl)) {
            return this.get('base_url') + '/';
        }
        else if (document.querySelector('base')) {
            return document.querySelector('base')['href'];
        }
        else {
            // 'https://site.com/subdomain/index.html/" => 'https://site.com/subdomain/'
            return window.location.href.replace(/([^\/]+\.\w+($|\/$))/, '');
        }
    };
    /**
     * Get app's asset base url.
     */
    Settings.prototype.getAssetUrl = function (suffix) {
        var uri = (this.get('vebto.assetsUrl') || this.getBaseUrl());
        var prefix = this.get('vebto.assetsPrefix');
        // in production assets will be in "client" sub-folder
        if (this.get('vebto.environment') === 'production' && prefix) {
            uri += prefix + '/';
        }
        uri += 'assets/';
        if (suffix)
            uri += suffix;
        return uri;
    };
    /**
     * Save specified setting on the server.
     */
    Settings.prototype.save = function (params) {
        this.setMultiple(params.client);
        var encoded = btoa(encodeURIComponent(JSON.stringify(params)));
        return this.http.post('settings', { settings: encoded });
    };
    /**
     * Check if any social login is enabled.
     */
    Settings.prototype.anySocialLoginEnabled = function () {
        var _this = this;
        var names = ['facebook', 'google', 'twitter'];
        return names.filter(function (name) { return _this.get('social.' + name + '.enable'); }).length > -1;
    };
    /**
     * Set HttpClient instance.
     */
    Settings.prototype.setHttpClient = function (http) {
        this.http = http;
    };
    Settings.ngInjectableDef = i0.defineInjectable({ factory: function Settings_Factory() { return new Settings(); }, token: Settings, providedIn: "root" });
    return Settings;
}());
export { Settings };
