var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { FormControl } from '@angular/forms';
import { FilterToolService } from '../../../../image-editor/tools/filter/filter-tool.service';
import { Select, Store } from '@ngxs/store';
import { FilterState } from '../../../state/filter/filter.state';
import { Observable } from 'rxjs';
var FilterControlsComponent = /** @class */ (function () {
    function FilterControlsComponent(filterTool, store) {
        this.filterTool = filterTool;
        this.store = store;
        this.colorFormControl = new FormControl();
    }
    FilterControlsComponent.prototype.applyFilterValue = function (optionName, value) {
        var name = this.store.selectSnapshot(FilterState.selectedFilter);
        var filter = this.filterTool.getByName(name);
        this.filterTool.applyValue(filter, optionName, value);
    };
    FilterControlsComponent.prototype.getFilterOptions = function (name) {
        return this.filterTool.getByName(name).options;
    };
    __decorate([
        Select(FilterState.selectedFilter),
        __metadata("design:type", Observable)
    ], FilterControlsComponent.prototype, "selectedFilter$", void 0);
    return FilterControlsComponent;
}());
export { FilterControlsComponent };
