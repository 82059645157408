/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stickers-drawer.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../common/core/translations/translate.directive";
import * as i3 from "../../../../../common/core/translations/translations.service";
import * as i4 from "../../../../../common/core/config/settings.service";
import * as i5 from "@angular/common";
import * as i6 from "./stickers-drawer.component";
import * as i7 from "../../../../image-editor/tools/shapes/shapes-tool.service";
import * as i8 from "@ngxs/store";
var styles_StickersDrawerComponent = [i0.styles];
var RenderType_StickersDrawerComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_StickersDrawerComponent, data: {} });
export { RenderType_StickersDrawerComponent as RenderType_StickersDrawerComponent };
function View_StickersDrawerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "bottom-label"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_StickersDrawerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["button", 1]], null, 3, "button", [["class", "sticker button-with-image"]], [[2, "no-label", null], [2, "dark-bg", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.addSticker(_v.parent.context.ngIf, _v.context.$implicit);
        var pd_0 = (i1.ɵnov(_v, 0).blur() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Sticker preview image"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StickersDrawerComponent_3)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_3 = _v.parent.context.ngIf.list; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_v.parent.context.ngIf.list; var currVal_1 = _v.parent.context.ngIf.darkBg; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.shapesTool.getStickerUrl(_v.parent.context.ngIf, _v.context.$implicit); _ck(_v, 1, 0, currVal_2); }); }
function View_StickersDrawerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StickersDrawerComponent_2)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getStickersIterable(_v.context.ngIf); _ck(_v, 2, 0, currVal_0); }, null); }
function View_StickersDrawerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "category button-with-image"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openStickersCategory(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Sticker category preview image"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "bottom-label"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(3, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shapesTool.getStickerCategoryUrl(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_1); }); }
function View_StickersDrawerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StickersDrawerComponent_5)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.categories; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_StickersDrawerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_StickersDrawerComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["categoryList", 2]], null, 0, null, View_StickersDrawerComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.activeCategory$)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_StickersDrawerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "stickers-drawer", [["class", "controls-drawer"]], null, null, null, View_StickersDrawerComponent_0, RenderType_StickersDrawerComponent)), i1.ɵdid(1, 49152, null, 0, i6.StickersDrawerComponent, [i7.ShapesToolService, i4.Settings, i8.Store], null, null)], null, null); }
var StickersDrawerComponentNgFactory = i1.ɵccf("stickers-drawer", i6.StickersDrawerComponent, View_StickersDrawerComponent_Host_0, {}, {}, []);
export { StickersDrawerComponentNgFactory as StickersDrawerComponentNgFactory };
