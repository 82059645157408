/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shapes-drawer.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../common/core/translations/translate.directive";
import * as i6 from "../../../../../common/core/translations/translations.service";
import * as i7 from "../../../../../common/core/config/settings.service";
import * as i8 from "./shapes-drawer.component";
import * as i9 from "@ngxs/store";
var styles_ShapesDrawerComponent = [i0.styles];
var RenderType_ShapesDrawerComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ShapesDrawerComponent, data: {} });
export { RenderType_ShapesDrawerComponent as RenderType_ShapesDrawerComponent };
function View_ShapesDrawerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["viewBox", "0 -100 512 700"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:path", [], [[1, "d", 0], [1, "stroke-width", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.options.path; var currVal_1 = (_v.parent.context.$implicit.options.strokeWidth || 10); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ShapesDrawerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = (_v.parent.context.$implicit.name + "-custom"); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ShapesDrawerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [["class", "button-with-image"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addShape(_v.context.$implicit.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShapesDrawerComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShapesDrawerComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "name bottom-label"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(6, 4341760, null, 0, i5.TranslateDirective, [i1.ElementRef, i6.Translations, i7.Settings], null, null), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type === "Path"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type !== "Path"); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_2); }); }
export function View_ShapesDrawerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShapesDrawerComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shapes; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ShapesDrawerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shapes-drawer", [["class", "controls-drawer"]], null, null, null, View_ShapesDrawerComponent_0, RenderType_ShapesDrawerComponent)), i1.ɵdid(1, 49152, null, 0, i8.ShapesDrawerComponent, [i7.Settings, i9.Store], null, null)], null, null); }
var ShapesDrawerComponentNgFactory = i1.ɵccf("shapes-drawer", i8.ShapesDrawerComponent, View_ShapesDrawerComponent_Host_0, {}, {}, []);
export { ShapesDrawerComponentNgFactory as ShapesDrawerComponentNgFactory };
