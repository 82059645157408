/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./gradient-controls-drawer.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./gradient-controls-drawer.component";
import * as i4 from "../../../../image-editor/canvas/active-object/active-object.service";
import * as i5 from "../../../../../common/core/config/settings.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../../../image-editor/tools/fill/fill-tool.service";
var styles_GradientControlsDrawerComponent = [i0.styles];
var RenderType_GradientControlsDrawerComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_GradientControlsDrawerComponent, data: {} });
export { RenderType_GradientControlsDrawerComponent as RenderType_GradientControlsDrawerComponent };
function View_GradientControlsDrawerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "gradient button-with-image no-label"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.fillWithGradient(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "bg-image"]], [[4, "background-image", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getGradientBgStyle((_v.context.index + 1)); _ck(_v, 1, 0, currVal_0); }); }
export function View_GradientControlsDrawerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GradientControlsDrawerComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.defaultGradients; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GradientControlsDrawerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gradient-controls-drawer", [], null, null, null, View_GradientControlsDrawerComponent_0, RenderType_GradientControlsDrawerComponent)), i1.ɵdid(1, 49152, null, 0, i3.GradientControlsDrawerComponent, [i4.ActiveObjectService, i5.Settings, i6.DomSanitizer, i7.FillToolService], null, null)], null, null); }
var GradientControlsDrawerComponentNgFactory = i1.ɵccf("gradient-controls-drawer", i3.GradientControlsDrawerComponent, View_GradientControlsDrawerComponent_Host_0, {}, {}, []);
export { GradientControlsDrawerComponentNgFactory as GradientControlsDrawerComponentNgFactory };
