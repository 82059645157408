var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { AfterViewInit } from '@angular/core';
import { Settings } from 'common/core/config/settings.service';
import { FloatingPanelsService } from '../floating-panels.service';
import { HistoryToolService } from '../../../image-editor/history/history-tool.service';
import { CanvasZoomService } from '../../../image-editor/canvas/canvas-zoom.service';
import { ExportToolService } from '../../../image-editor/tools/export/export-tool.service';
import { CanvasService } from '../../../image-editor/canvas/canvas.service';
import { delay } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material';
import { BreakpointsService } from '../../../../common/core/ui/breakpoints.service';
import { ImportToolService } from '../../../image-editor/tools/import/import-tool.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { EditorState } from '../../../image-editor/state/editor-state';
import { ApplyChanges, CancelChanges, CloseEditor } from '../../../image-editor/state/editor-state-actions';
import { HistoryNames } from '../../../image-editor/history/history-names.enum';
import { HistoryState } from '../../state/history/history.state';
import { startCase } from '../../../../common/core/utils/start-case';
var ToolbarComponent = /** @class */ (function () {
    function ToolbarComponent(history, config, zoom, panels, breakpoints, importTool, exportTool, canvas, floatingPanels, store) {
        this.history = history;
        this.config = config;
        this.zoom = zoom;
        this.panels = panels;
        this.breakpoints = breakpoints;
        this.importTool = importTool;
        this.exportTool = exportTool;
        this.canvas = canvas;
        this.floatingPanels = floatingPanels;
        this.store = store;
        this.compactMode = new BehaviorSubject(false);
    }
    ToolbarComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.canvas.state.loaded.pipe(delay(0)).subscribe(function () {
            _this.floatingPanels.openSampleImagePanel();
        });
        this.breakpoints.observe('(max-width: 768px)')
            .subscribe(function (result) { return _this.compactMode.next(result.matches); });
    };
    ToolbarComponent.prototype.zoomIn = function () {
        this.zoom.set(this.zoom.get() + 0.05);
    };
    ToolbarComponent.prototype.zoomOut = function () {
        this.zoom.set(this.zoom.get() - 0.05);
    };
    ToolbarComponent.prototype.exportImage = function () {
        if (this.config.get('pixie.ui.showExportPanel')) {
            this.panels.openExportPanel();
        }
        else {
            this.exportTool.export();
        }
    };
    /**
     * Ask user to upload a new background image and override current one.
     */
    ToolbarComponent.prototype.openBackgroundImage = function () {
        var _this = this;
        this.importTool.openUploadDialog({ type: 'image', backgroundImage: true }).then(function () {
            _this.history.add(HistoryNames.BG_IMAGE);
        });
    };
    /**
     * Ask user to upload a new overlay image and add it to canvas.
     */
    ToolbarComponent.prototype.openOverlayImage = function () {
        var _this = this;
        this.importTool.openUploadDialog().then(function (obj) {
            if (!obj)
                return;
            _this.canvas.fabric().setActiveObject(obj);
            _this.history.add(HistoryNames.OVERLAY_IMAGE);
        });
    };
    /**
     * Ask user to upload state file and override current editor state.
     */
    ToolbarComponent.prototype.openStateFile = function () {
        return this.importTool.openUploadDialog({ type: 'state' });
    };
    /**
     * Execute custom open button action or open dropdown menu if no action provided.
     */
    ToolbarComponent.prototype.executeOpenButtonAction = function () {
        var action = this.config.get('pixie.ui.toolbar.openButtonAction');
        if (!action) {
            this.matMenuTrigger.toggleMenu();
        }
        else {
            action();
        }
    };
    ToolbarComponent.prototype.applyChanges = function () {
        var panel = this.store.selectSnapshot(EditorState.activePanel);
        this.store.dispatch(new ApplyChanges(panel));
    };
    ToolbarComponent.prototype.cancelChanges = function () {
        var panel = this.store.selectSnapshot(EditorState.activePanel);
        this.store.dispatch(new CancelChanges(panel));
    };
    ToolbarComponent.prototype.closeEditor = function () {
        this.store.dispatch(new CloseEditor());
    };
    ToolbarComponent.prototype.getToolDisplayName = function (name) {
        return startCase(name);
    };
    __decorate([
        Select(EditorState.mode),
        __metadata("design:type", Observable)
    ], ToolbarComponent.prototype, "mode$", void 0);
    __decorate([
        Select(EditorState.zoom),
        __metadata("design:type", Observable)
    ], ToolbarComponent.prototype, "zoom$", void 0);
    __decorate([
        Select(EditorState.activePanel),
        __metadata("design:type", Observable)
    ], ToolbarComponent.prototype, "activePanel$", void 0);
    __decorate([
        Select(HistoryState.canUndo),
        __metadata("design:type", Observable)
    ], ToolbarComponent.prototype, "canUndo$", void 0);
    __decorate([
        Select(HistoryState.canRedo),
        __metadata("design:type", Observable)
    ], ToolbarComponent.prototype, "canRedo$", void 0);
    return ToolbarComponent;
}());
export { ToolbarComponent };
