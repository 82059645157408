export enum Keycodes {
    ENTER = 13,
    SPACE = 32,
    ESCAPE = 27,
    DELETE = 46,
    ARROW_UP = 38,
    ARROW_RIGHT = 39,
    ARROW_DOWN = 40,
    ARROW_LEFT = 37,
    S = 83,
    N = 78,
    A = 65,
    T = 84,
    B = 66,
    C = 67,
    O = 79,
    P = 80,
    R = 82,
    F = 70,
    Y = 89,
    Z = 90,
}
