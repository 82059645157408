var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FrameToolService } from '../../../../image-editor/tools/frame/frame-tool.service';
import { ActiveFrameService } from '../../../../image-editor/tools/frame/active-frame.service';
import { Select, Store } from '@ngxs/store';
import { MarkAsDirty, OpenFrameControls } from '../../../state/frame/frame.actions';
import { FrameState } from '../../../state/frame/frame.state';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
var FrameDrawerComponent = /** @class */ (function () {
    function FrameDrawerComponent(frameTool, activeFrame, store) {
        this.frameTool = frameTool;
        this.activeFrame = activeFrame;
        this.store = store;
        this.frameControlsForm = new FormGroup({
            size: new FormControl(),
            color: new FormControl(),
        });
    }
    FrameDrawerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.frameControlsForm.get('color').valueChanges.subscribe(function (value) {
            _this.frameTool.changeColor(value);
        });
        this.frameControlsForm.valueChanges
            .pipe(debounceTime(150))
            .subscribe(function () {
            _this.markAsDirty();
        });
    };
    FrameDrawerComponent.prototype.getFrameThumbUrl = function (frame) {
        return this.frameTool.patterns.getBaseUrl(frame) + '/thumbnail.png';
    };
    FrameDrawerComponent.prototype.selectFrame = function (frame) {
        this.markAsDirty();
        this.frameTool.add(frame.name);
    };
    FrameDrawerComponent.prototype.scaleFrame = function (value) {
        this.frameTool.resize(value);
    };
    FrameDrawerComponent.prototype.frameIsActive = function (frame) {
        return this.activeFrame.is(frame);
    };
    FrameDrawerComponent.prototype.removeFrame = function () {
        this.activeFrame.remove();
    };
    FrameDrawerComponent.prototype.showFrameConfig = function (frame) {
        this.frameControlsForm.patchValue({ size: frame.size.default }, { emitEvent: false });
        this.store.dispatch(new OpenFrameControls());
    };
    FrameDrawerComponent.prototype.markAsDirty = function () {
        this.store.dispatch(new MarkAsDirty());
    };
    __decorate([
        Select(FrameState.controlsOpen),
        __metadata("design:type", Observable)
    ], FrameDrawerComponent.prototype, "controlsOpen$", void 0);
    return FrameDrawerComponent;
}());
export { FrameDrawerComponent };
