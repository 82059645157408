/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./color-widget.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "../../../../../common/core/translations/translate.directive";
import * as i11 from "../../../../../common/core/translations/translations.service";
import * as i12 from "../../../../../common/core/config/settings.service";
import * as i13 from "@angular/forms";
import * as i14 from "./color-widget.component";
import * as i15 from "../../../../../common/core/ui/overlay-panel/overlay-panel.service";
var styles_ColorWidgetComponent = [i0.styles];
var RenderType_ColorWidgetComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ColorWidgetComponent, data: {} });
export { RenderType_ColorWidgetComponent as RenderType_ColorWidgetComponent };
function View_ColorWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "no-style color control"]], [[4, "background", null], [2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeColor(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = (i1.ɵunv(_v, 0, 1, i1.ɵnov(_v, 1).transform(_co.selectedColor$)) === _v.context.$implicit); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ColorWidgetComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { colorPickerButton: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "colors"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0]], null, 3, "button", [["class", "color-picker-button"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openColorPicker() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(3, 180224, [["colorPickerButton", 4]], 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "color-wheel-custom"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorWidgetComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "bottom-label"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(9, 4341760, null, 0, i10.TranslateDirective, [i1.ElementRef, i11.Translations, i12.Settings], null, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_4 = "color-wheel-custom"; _ck(_v, 5, 0, currVal_4); var currVal_5 = _co.colors; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3).disabled || null); var currVal_1 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 5).inline; var currVal_3 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_2, currVal_3); }); }
export function View_ColorWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "color-widget", [["class", "color-widget"]], [[2, "compact", null]], null, null, View_ColorWidgetComponent_0, RenderType_ColorWidgetComponent)), i1.ɵprd(5120, null, i13.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i14.ColorWidgetComponent]), i1.ɵdid(2, 49152, null, 0, i14.ColorWidgetComponent, [i15.OverlayPanel, i12.Settings], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).compact; _ck(_v, 0, 0, currVal_0); }); }
var ColorWidgetComponentNgFactory = i1.ɵccf("color-widget", i14.ColorWidgetComponent, View_ColorWidgetComponent_Host_0, { compact: "compact" }, {}, ["*"]);
export { ColorWidgetComponentNgFactory as ColorWidgetComponentNgFactory };
