var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { State, Action, Selector, createSelector } from '@ngxs/store';
import { AddHistoryItem, ReplaceCurrentItem, ResetHistory, UpdatePointerById } from './history.actions';
var HistoryState = /** @class */ (function () {
    function HistoryState() {
    }
    HistoryState_1 = HistoryState;
    HistoryState.canUndo = function (_a) {
        var pointer = _a.pointer;
        return pointer - 1 > -1;
    };
    HistoryState.canRedo = function (_a) {
        var items = _a.items, pointer = _a.pointer;
        return items.length > (pointer + 1);
    };
    HistoryState.items = function (state) {
        return state.items;
    };
    HistoryState.activeItemId = function (state) {
        return state.items[state.pointer].id;
    };
    HistoryState.item = function (which) {
        return createSelector([HistoryState_1], function (state) {
            var index = state.pointer;
            switch (which) {
                case 'previous':
                    index = state.pointer - 1;
                    break;
                case 'next':
                    index = state.pointer + 1;
                    break;
                default:
                    index = state.pointer;
            }
            return state.items[index];
        });
    };
    HistoryState.prototype.addItem = function (ctx, _a) {
        var item = _a.item;
        var newItems = ctx.getState().items.concat([item]);
        ctx.patchState({
            items: newItems,
            pointer: newItems.length - 1
        });
    };
    HistoryState.prototype.replaceCurrentItem = function (ctx, action) {
        var items = ctx.getState().items.slice();
        items[ctx.getState().pointer] = action.item;
        ctx.patchState({ items: items });
    };
    HistoryState.prototype.updatePointerById = function (ctx, _a) {
        var id = _a.id;
        var index = ctx.getState().items.findIndex(function (item) { return item.id === id; });
        ctx.patchState({ pointer: index });
    };
    HistoryState.prototype.resetHistory = function (ctx) {
        ctx.patchState({
            items: [],
            pointer: 0
        });
    };
    var HistoryState_1;
    __decorate([
        Action(AddHistoryItem),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, AddHistoryItem]),
        __metadata("design:returntype", void 0)
    ], HistoryState.prototype, "addItem", null);
    __decorate([
        Action(ReplaceCurrentItem),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, ReplaceCurrentItem]),
        __metadata("design:returntype", void 0)
    ], HistoryState.prototype, "replaceCurrentItem", null);
    __decorate([
        Action(UpdatePointerById),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, UpdatePointerById]),
        __metadata("design:returntype", void 0)
    ], HistoryState.prototype, "updatePointerById", null);
    __decorate([
        Action(ResetHistory),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], HistoryState.prototype, "resetHistory", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], HistoryState, "canUndo", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], HistoryState, "canRedo", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], HistoryState, "items", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], HistoryState, "activeItemId", null);
    HistoryState = HistoryState_1 = __decorate([
        State({
            name: 'history',
            defaults: {
                items: [],
                pointer: 0,
            }
        })
    ], HistoryState);
    return HistoryState;
}());
export { HistoryState };
