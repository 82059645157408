var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ObjectsService } from '../../../image-editor/objects/objects.service';
import { OverlayPanelRef } from 'common/core/ui/overlay-panel/overlay-panel-ref';
import { EditorControlsService } from '../../toolbar-controls/editor-controls.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { CanvasStateService } from '../../../image-editor/canvas/canvas-state.service';
import { Select, Store } from '@ngxs/store';
import { EditorState } from '../../../image-editor/state/editor-state';
import { OpenPanel } from '../../../image-editor/state/editor-state-actions';
import { DrawerName } from '../../toolbar-controls/drawers/drawer-name.enum';
import { ObjectNames } from '../../../image-editor/objects/object-names.enum';
import { Observable } from 'rxjs';
var ObjectsPanelComponent = /** @class */ (function () {
    function ObjectsPanelComponent(objects, panelRef, controls, canvasState, store) {
        this.objects = objects;
        this.panelRef = panelRef;
        this.controls = controls;
        this.canvasState = canvasState;
        this.store = store;
    }
    ObjectsPanelComponent.prototype.getIcon = function (object) {
        return ObjectNames[object.name].icon;
    };
    ObjectsPanelComponent.prototype.selectObject = function (object) {
        this.objects.select(object);
        if (!this.store.selectSnapshot(EditorState.dirty)) {
            this.store.dispatch(new OpenPanel(DrawerName.OBJECT_SETTINGS));
        }
    };
    ObjectsPanelComponent.prototype.getObjectDisplayName = function (object) {
        var name = object.name;
        return name ? name.replace(/([A-Z])/g, ' $1') : '';
    };
    ObjectsPanelComponent.prototype.reorderObjects = function (e) {
        moveItemInArray(this.objects.getAll(), e.previousIndex, e.currentIndex);
        // pixie and canvas object orders are reversed, need to
        // reverse newIndex given by cdk drag and drop
        var index = this.objects.getAll()
            .slice().reverse().findIndex(function (obj) { return obj.data.id === e.item.data; });
        this.objects.getById(e.item.data).moveTo(index);
        this.canvasState.fabric.requestRenderAll();
    };
    ObjectsPanelComponent.prototype.shouldDisableObject = function (object) {
        return !object.selectable && object.name !== ObjectNames.drawing.name;
    };
    __decorate([
        Select(EditorState.activeObjId),
        __metadata("design:type", Observable)
    ], ObjectsPanelComponent.prototype, "activeObjId$", void 0);
    return ObjectsPanelComponent;
}());
export { ObjectsPanelComponent };
