/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./texture-controls-drawer.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../../common/core/translations/translate.directive";
import * as i5 from "../../../../../common/core/translations/translations.service";
import * as i6 from "../../../../../common/core/config/settings.service";
import * as i7 from "@angular/common";
import * as i8 from "./texture-controls-drawer.component";
import * as i9 from "../../../../image-editor/canvas/active-object/active-object.service";
import * as i10 from "@angular/platform-browser";
import * as i11 from "../../../../image-editor/tools/fill/fill-tool.service";
import * as i12 from "../../../../image-editor/tools/import/import-tool.service";
var styles_TextureControlsDrawerComponent = [i0.styles];
var RenderType_TextureControlsDrawerComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TextureControlsDrawerComponent, data: {} });
export { RenderType_TextureControlsDrawerComponent as RenderType_TextureControlsDrawerComponent };
function View_TextureControlsDrawerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "texture button-with-image"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.fillWithPattern(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "bg-image"]], [[4, "background-image", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTextureBgStyle(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
export function View_TextureControlsDrawerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "button-with-image"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openUploadDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "add-image-custom"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "bottom-label"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(4, 4341760, null, 0, i4.TranslateDirective, [i1.ElementRef, i5.Translations, i6.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Upload"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TextureControlsDrawerComponent_1)), i1.ɵdid(7, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "add-image-custom"; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.defaultTextures; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_TextureControlsDrawerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "texture-controls-drawer", [], null, null, null, View_TextureControlsDrawerComponent_0, RenderType_TextureControlsDrawerComponent)), i1.ɵdid(1, 49152, null, 0, i8.TextureControlsDrawerComponent, [i9.ActiveObjectService, i6.Settings, i10.DomSanitizer, i11.FillToolService, i12.ImportToolService], null, null)], null, null); }
var TextureControlsDrawerComponentNgFactory = i1.ɵccf("texture-controls-drawer", i8.TextureControlsDrawerComponent, View_TextureControlsDrawerComponent_Host_0, {}, {}, []);
export { TextureControlsDrawerComponentNgFactory as TextureControlsDrawerComponentNgFactory };
