/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filter-controls.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../widgets/color-widget/color-widget.component.ngfactory";
import * as i3 from "../../widgets/color-widget/color-widget.component";
import * as i4 from "../../../../../common/core/ui/overlay-panel/overlay-panel.service";
import * as i5 from "../../../../../common/core/config/settings.service";
import * as i6 from "@angular/forms";
import * as i7 from "../../../../../common/core/translations/translate.directive";
import * as i8 from "../../../../../common/core/translations/translations.service";
import * as i9 from "@angular/common";
import * as i10 from "../../../../../../node_modules/@angular/material/slider/typings/index.ngfactory";
import * as i11 from "@angular/material/slider";
import * as i12 from "@angular/cdk/a11y";
import * as i13 from "@angular/cdk/bidi";
import * as i14 from "@angular/platform-browser/animations";
import * as i15 from "./filter-controls.component";
import * as i16 from "../../../../image-editor/tools/filter/filter-tool.service";
import * as i17 from "@ngxs/store";
var styles_FilterControlsComponent = [i0.styles];
var RenderType_FilterControlsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FilterControlsComponent, data: {} });
export { RenderType_FilterControlsComponent as RenderType_FilterControlsComponent };
function View_FilterControlsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "color-widget", [["class", "color-widget"]], [[2, "compact", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = (_co.applyFilterValue("color", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ColorWidgetComponent_0, i2.RenderType_ColorWidgetComponent)), i1.ɵdid(2, 49152, null, 0, i3.ColorWidgetComponent, [i4.OverlayPanel, i5.Settings], null, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.ColorWidgetComponent]), i1.ɵdid(4, 540672, null, 0, i6.FormControlDirective, [[8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR], [2, i6.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.FormControlDirective]), i1.ɵdid(6, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.colorFormControl; _ck(_v, 4, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).compact; var currVal_1 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 6).ngClassValid; var currVal_6 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_FilterControlsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "option", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i6.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i6.ɵangular_packages_forms_forms_s, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(3, 4341760, null, 0, i7.TranslateDirective, [i1.ElementRef, i8.Translations, i5.Settings], null, null), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit; _ck(_v, 4, 0, currVal_2); }); }
function View_FilterControlsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "input-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [["trans", ""]], [[8, "htmlFor", 0]], null, null, null, null)), i1.ɵdid(2, 4341760, null, 0, i7.TranslateDirective, [i1.ElementRef, i8.Translations, i5.Settings], null, null), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "select", [], [[8, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.applyFilterValue(_v.parent.context.$implicit.key, $event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterControlsComponent_5)), i1.ɵdid(6, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_3 = _v.parent.context.$implicit.value.available; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.key; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.key; _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.key; _ck(_v, 4, 0, currVal_2); }); }
function View_FilterControlsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "slider-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "slider-label"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(2, 4341760, null, 0, i7.TranslateDirective, [i1.ElementRef, i8.Translations, i5.Settings], null, null), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "mat-slider", [["class", "mat-slider"], ["role", "slider"]], [[8, "tabIndex", 0], [1, "aria-disabled", 0], [1, "aria-valuemax", 0], [1, "aria-valuemin", 0], [1, "aria-valuenow", 0], [1, "aria-orientation", 0], [2, "mat-slider-disabled", null], [2, "mat-slider-has-ticks", null], [2, "mat-slider-horizontal", null], [2, "mat-slider-axis-inverted", null], [2, "mat-slider-sliding", null], [2, "mat-slider-thumb-label-showing", null], [2, "mat-slider-vertical", null], [2, "mat-slider-min-value", null], [2, "mat-slider-hide-last-tick", null], [2, "_mat-animation-noopable", null]], [[null, "input"], [null, "focus"], [null, "blur"], [null, "mousedown"], [null, "keydown"], [null, "keyup"], [null, "mouseenter"], [null, "slide"], [null, "slideend"], [null, "slidestart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6)._onFocus() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6)._onBlur() !== false);
        ad = (pd_1 && ad);
    } if (("mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6)._onMousedown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6)._onKeydown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keyup" === en)) {
        var pd_4 = (i1.ɵnov(_v, 6)._onKeyup() !== false);
        ad = (pd_4 && ad);
    } if (("mouseenter" === en)) {
        var pd_5 = (i1.ɵnov(_v, 6)._onMouseenter() !== false);
        ad = (pd_5 && ad);
    } if (("slide" === en)) {
        var pd_6 = (i1.ɵnov(_v, 6)._onSlide($event) !== false);
        ad = (pd_6 && ad);
    } if (("slideend" === en)) {
        var pd_7 = (i1.ɵnov(_v, 6)._onSlideEnd() !== false);
        ad = (pd_7 && ad);
    } if (("slidestart" === en)) {
        var pd_8 = (i1.ɵnov(_v, 6)._onSlideStart($event) !== false);
        ad = (pd_8 && ad);
    } if (("input" === en)) {
        var pd_9 = (_co.applyFilterValue(_v.parent.context.$implicit.key, $event.value) !== false);
        ad = (pd_9 && ad);
    } return ad; }, i10.View_MatSlider_0, i10.RenderType_MatSlider)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i11.MatSlider]), i1.ɵdid(6, 245760, null, 0, i11.MatSlider, [i1.ElementRef, i12.FocusMonitor, i1.ChangeDetectorRef, [2, i13.Directionality], [8, null], [2, i14.ANIMATION_MODULE_TYPE]], { max: [0, "max"], min: [1, "min"], step: [2, "step"], value: [3, "value"] }, { input: "input" })], function (_ck, _v) { var currVal_17 = (_v.parent.context.$implicit.value.max || 100); var currVal_18 = (_v.parent.context.$implicit.value.min || 0); var currVal_19 = (_v.parent.context.$implicit.value.step || 1); var currVal_20 = _v.parent.context.$implicit.value.current; _ck(_v, 6, 0, currVal_17, currVal_18, currVal_19, currVal_20); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.key; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).tabIndex; var currVal_2 = i1.ɵnov(_v, 6).disabled; var currVal_3 = i1.ɵnov(_v, 6).max; var currVal_4 = i1.ɵnov(_v, 6).min; var currVal_5 = i1.ɵnov(_v, 6).value; var currVal_6 = (i1.ɵnov(_v, 6).vertical ? "vertical" : "horizontal"); var currVal_7 = i1.ɵnov(_v, 6).disabled; var currVal_8 = i1.ɵnov(_v, 6).tickInterval; var currVal_9 = !i1.ɵnov(_v, 6).vertical; var currVal_10 = i1.ɵnov(_v, 6)._invertAxis; var currVal_11 = i1.ɵnov(_v, 6)._isSliding; var currVal_12 = i1.ɵnov(_v, 6).thumbLabel; var currVal_13 = i1.ɵnov(_v, 6).vertical; var currVal_14 = i1.ɵnov(_v, 6)._isMinValue; var currVal_15 = (i1.ɵnov(_v, 6).disabled || ((i1.ɵnov(_v, 6)._isMinValue && i1.ɵnov(_v, 6)._thumbGap) && i1.ɵnov(_v, 6)._invertAxis)); var currVal_16 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 4, 1, [currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16]); }); }
function View_FilterControlsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterControlsComponent_3)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterControlsComponent_4)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterControlsComponent_6)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.value.type === "colorPicker"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.value.type === "select"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.value.type === "slider"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_FilterControlsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FilterControlsComponent_2)), i1.ɵdid(2, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i9.KeyValuePipe, [i1.KeyValueDiffers]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.getFilterOptions(_v.context.ngIf))); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FilterControlsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_FilterControlsComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.selectedFilter$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FilterControlsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "filter-controls", [], null, null, null, View_FilterControlsComponent_0, RenderType_FilterControlsComponent)), i1.ɵdid(1, 49152, null, 0, i15.FilterControlsComponent, [i16.FilterToolService, i17.Store], null, null)], null, null); }
var FilterControlsComponentNgFactory = i1.ɵccf("filter-controls", i15.FilterControlsComponent, View_FilterControlsComponent_Host_0, {}, {}, []);
export { FilterControlsComponentNgFactory as FilterControlsComponentNgFactory };
