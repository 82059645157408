import { ElementRef } from '@angular/core';
import { Settings } from 'common/core/config/settings.service';
import { OpenSampleImagePanelComponent } from './open-sample-image-panel.component';
import { OverlayPanel } from 'common/core/ui/overlay-panel/overlay-panel.service';
import { CanvasStateService } from '../../../image-editor/canvas/canvas-state.service';
import { Store } from '@ngxs/store';
import { EditorState } from '../../../image-editor/state/editor-state';
import * as i0 from "@angular/core";
import * as i1 from "../../../image-editor/canvas/canvas-state.service";
import * as i2 from "../../../../common/core/config/settings.service";
import * as i3 from "../../../../common/core/ui/overlay-panel/overlay-panel.service";
import * as i4 from "@ngxs/store";
var OpenSampleImagePanelService = /** @class */ (function () {
    function OpenSampleImagePanelService(canvasState, config, overlayPanel, store) {
        this.canvasState = canvasState;
        this.config = config;
        this.overlayPanel = overlayPanel;
        this.store = store;
    }
    OpenSampleImagePanelService.prototype.open = function () {
        if (!this.shouldShowOpenImageDialog())
            return;
        var positionStrategy = this.overlayPanel.overlay.position()
            .flexibleConnectedTo(new ElementRef(this.canvasState.wrapperEl))
            .withPositions([{ overlayX: 'center', overlayY: 'center', originX: 'center', originY: 'center' }]);
        this.sampleImagePanelRef = this.overlayPanel.open(OpenSampleImagePanelComponent, {
            hasBackdrop: true,
            closeOnBackdropClick: false,
            positionStrategy: positionStrategy,
            panelClass: ['floating-panel', 'sample-image-panel']
        });
    };
    OpenSampleImagePanelService.prototype.reposition = function () {
        if (!this.sampleImagePanelRef)
            return;
        this.sampleImagePanelRef.updatePosition();
    };
    /**
     * Check if "open image" dialog window should be shown.
     */
    OpenSampleImagePanelService.prototype.shouldShowOpenImageDialog = function () {
        return this.store.selectSnapshot(EditorState.visible) &&
            this.canvasState.isEmpty() &&
            this.config.get('pixie.ui.openImageDialog.show') &&
            !this.sampleImagePanelRef;
    };
    OpenSampleImagePanelService.ngInjectableDef = i0.defineInjectable({ factory: function OpenSampleImagePanelService_Factory() { return new OpenSampleImagePanelService(i0.inject(i1.CanvasStateService), i0.inject(i2.Settings), i0.inject(i3.OverlayPanel), i0.inject(i4.Store)); }, token: OpenSampleImagePanelService, providedIn: "root" });
    return OpenSampleImagePanelService;
}());
export { OpenSampleImagePanelService };
