/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./colorpicker-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-color-picker";
import * as i3 from "./colorpicker-panel.component";
import * as i4 from "../overlay-panel/overlay-panel-data";
import * as i5 from "../overlay-panel/overlay-panel-ref";
var styles_ColorpickerPanelComponent = [i0.styles];
var RenderType_ColorpickerPanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ColorpickerPanelComponent, data: {} });
export { RenderType_ColorpickerPanelComponent as RenderType_ColorpickerPanelComponent };
export function View_ColorpickerPanelComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { trigger: 0 }), (_l()(), i1.ɵeld(1, 16777216, [[1, 0], ["trigger", 1]], null, 1, "div", [["class", "trigger"]], null, [[null, "colorPickerChange"], [null, "click"], [null, "focus"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).handleClick() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).handleFocus() !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).handleInput($event) !== false);
        ad = (pd_2 && ad);
    } if (("colorPickerChange" === en)) {
        var pd_3 = (_co.emitSelectedEvent($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.ColorPickerDirective, [i1.Injector, i1.ComponentFactoryResolver, i1.ApplicationRef, i1.ViewContainerRef, i1.ElementRef, i2.ColorPickerService], { colorPicker: [0, "colorPicker"], cpToggle: [1, "cpToggle"], cpOutputFormat: [2, "cpOutputFormat"], cpAlphaChannel: [3, "cpAlphaChannel"], cpDialogDisplay: [4, "cpDialogDisplay"] }, { colorPickerChange: "colorPickerChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.color; var currVal_1 = false; var currVal_2 = "hex"; var currVal_3 = "always"; var currVal_4 = "inline"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_ColorpickerPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "colorpicker-panel", [], null, null, null, View_ColorpickerPanelComponent_0, RenderType_ColorpickerPanelComponent)), i1.ɵdid(1, 114688, null, 0, i3.ColorpickerPanelComponent, [[2, i4.OVERLAY_PANEL_DATA], i5.OverlayPanelRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColorpickerPanelComponentNgFactory = i1.ɵccf("colorpicker-panel", i3.ColorpickerPanelComponent, View_ColorpickerPanelComponent_Host_0, {}, {}, []);
export { ColorpickerPanelComponentNgFactory as ColorpickerPanelComponentNgFactory };
