import { ApplicationRef, Injector } from '@angular/core';
import { AppComponent } from './app.component';
import { MERGED_CONFIG } from './image-editor/default-settings';
import { noBackendErrorHandlerFactory } from 'common/core/errors/no-backend-error-handler';
var ɵ0 = noBackendErrorHandlerFactory;
var AppModule = /** @class */ (function () {
    function AppModule(injector) {
        this.injector = injector;
    }
    AppModule.prototype.ngDoBootstrap = function (app) {
        var selector = this.injector.get(MERGED_CONFIG).selector;
        app.bootstrap(AppComponent, selector);
    };
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
