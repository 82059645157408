import { AppModule } from '../app.module';
import { DEFAULT_CONFIG, MERGED_CONFIG, PIXIE_VERSION } from './default-settings';
import merge from 'deepmerge';
import * as Dot from "dot-object";
import { ApplicationRef } from '@angular/core';
import { CanvasService } from './canvas/canvas.service';
import { Settings } from 'common/core/config/settings.service';
import { ToolsService } from './tools/tools.service';
import { HttpClient } from '@angular/common/http';
import { OpenSampleImagePanelService } from '../image-editor-ui/panels/open-sample-image-panel/open-sample-image-panel.service';
import { EditorControlsService } from '../image-editor-ui/toolbar-controls/editor-controls.service';
import { HistoryToolService } from './history/history-tool.service';
import { ImportToolService } from './tools/import/import-tool.service';
import { Store } from '@ngxs/store';
import { CloseEditor, OpenEditor } from './state/editor-state-actions';
import { delay } from 'rxjs/operators';
import * as __NgCli_bootstrap_1 from "./../app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
var PixieBootstrapper = /** @class */ (function () {
    function PixieBootstrapper(config) {
        this.version = PIXIE_VERSION;
        this.initPixie(config);
    }
    /**
     * Open specified image and then editor.
     */
    PixieBootstrapper.prototype.openEditorWithImage = function (data, asMainImage) {
        var _this = this;
        if (asMainImage === void 0) { asMainImage = true; }
        this.openFile(data, 'png', asMainImage).then(function () { return _this.open(); });
    };
    /**
     * Open specified photo as main canvas image.
     */
    PixieBootstrapper.prototype.openMainImage = function (data) {
        this.openFile(data, 'png', true);
    };
    /**
     * Open specified file in the editor.
     */
    PixieBootstrapper.prototype.openFile = function (data, extension, asMainImage) {
        if (extension === void 0) { extension = 'png'; }
        if (asMainImage === void 0) { asMainImage = false; }
        var importTool = this.getTool('import');
        var promise;
        if (asMainImage) {
            promise = importTool.openBackgroundImage(data);
        }
        else {
            promise = importTool.openFile(data, extension);
        }
        this.injector.get(ApplicationRef).tick();
        return promise;
    };
    /**
     * Open new canvas with specified with and height.
     */
    PixieBootstrapper.prototype.newFile = function (width, height) {
        return this.getTool('canvas').openNew(width, height);
    };
    /**
     * Load canvas state from json.
     */
    PixieBootstrapper.prototype.loadState = function (state) {
        return this.getTool('history').addFromJson(state);
    };
    /**
     * Get current canvas state as json.
     */
    PixieBootstrapper.prototype.getState = function () {
        return JSON.stringify(this.getTool('history').getCurrentCanvasState());
    };
    /**
     * Open pixie editor.
     */
    PixieBootstrapper.prototype.open = function (config) {
        var _this = this;
        if (config)
            this.mergeConfig(config);
        this.get(Store).dispatch(new OpenEditor()).pipe(delay(1)).subscribe(function () {
            _this.getTool('canvas').zoom.fitToScreen();
            _this.get(OpenSampleImagePanelService).open();
            _this.get(HistoryToolService).addInitial();
        });
    };
    /**
     * Close pixie editor.
     */
    PixieBootstrapper.prototype.close = function () {
        return this.get(Store).dispatch(new CloseEditor());
    };
    PixieBootstrapper.prototype.resetEditor = function (key, value) {
        var _this = this;
        return new Promise(function (resolve) {
            // reset fabric and UI
            _this.get(ImportToolService).resetEditor();
            // set new config, if provided
            if (key)
                _this.setConfig(key, value);
            // re-init canvas
            _this.getTool('canvas').initContent().then(function () {
                _this.get(EditorControlsService).closeCurrentPanel();
                _this.get(OpenSampleImagePanelService).open();
                if (key)
                    _this.get(HistoryToolService).addInitial();
                resolve();
            });
        });
    };
    PixieBootstrapper.prototype.resetAndOpenEditor = function (key, value) {
        var _this = this;
        this.resetEditor(key, value).then(function () { return _this.open(); });
    };
    /**
     * Set specified config value via dot notation.
     */
    PixieBootstrapper.prototype.setConfig = function (key, value) {
        var settings = this.get(Settings);
        // set config if key and value is provided
        if (typeof key === 'string' && typeof value !== 'undefined') {
            var prefixedKey = key.indexOf('vebto.') > -1 ? key : 'pixie.' + key;
            settings.set(prefixedKey, value, true);
            // set config if config object is provided
        }
        else if (typeof key === 'object') {
            var config = { pixie: key };
            if (config.pixie['sentry_public']) {
                settings.set('logging.sentry_public', config.pixie['sentry_public']);
            }
            settings.merge(config, true);
        }
    };
    PixieBootstrapper.prototype.getDefaultConfig = function (key) {
        return Dot.pick(key, DEFAULT_CONFIG);
    };
    /**
     * Get pixie http client.
     */
    PixieBootstrapper.prototype.http = function () {
        return this.get(HttpClient);
    };
    /**
     * Get pixie tool by specified name.
     */
    PixieBootstrapper.prototype.getTool = function (name) {
        if (!this.injector)
            throw ('Pixie is not loaded yet. Are you using "onLoad" callback?');
        return this.get(ToolsService).get(name);
    };
    PixieBootstrapper.prototype.get = function (token) {
        return this.injector.get(token);
    };
    PixieBootstrapper.prototype.initPixie = function (config) {
        var merged = this.mergeConfig(config);
        __NgCli_bootstrap_2.platformBrowser([{ provide: MERGED_CONFIG, useValue: merged }])
            .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
            .then(this.onAngularReady.bind(this))
            .catch(function (err) { return console.log(err); });
    };
    PixieBootstrapper.prototype.mergeConfig = function (userConfig) {
        var merged = merge(DEFAULT_CONFIG, userConfig || {});
        return this.replaceDefaultConfigItems(merged, userConfig);
    };
    /**
     * Remove default items if "replaceDefault" is true in user config.
     */
    PixieBootstrapper.prototype.replaceDefaultConfigItems = function (config, userConfig) {
        for (var key in config) {
            if (key === 'replaceDefault' && config[key]) {
                config['items'] = userConfig ? userConfig['items'] : [];
            }
            else if (typeof config[key] === 'object') {
                this.replaceDefaultConfigItems(config[key], userConfig && userConfig[key]);
            }
        }
        return config;
    };
    PixieBootstrapper.prototype.onAngularReady = function (appModule) {
        var _this = this;
        this.appModule = appModule;
        this.injector = appModule.injector;
        this.get(CanvasService).state.loaded.subscribe(function () {
            var settings = _this.get(Settings);
            var onLoad = settings.get('pixie.onLoad');
            if (onLoad)
                onLoad();
        });
    };
    PixieBootstrapper.prototype.destroyEditor = function () {
        this.appModule.destroy();
        this.appModule = null;
    };
    return PixieBootstrapper;
}());
export { PixieBootstrapper };
