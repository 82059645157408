/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./canvas-background-drawer.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../widgets/color-widget/color-widget.component.ngfactory";
import * as i3 from "../../widgets/color-widget/color-widget.component";
import * as i4 from "../../../../../common/core/ui/overlay-panel/overlay-panel.service";
import * as i5 from "../../../../../common/core/config/settings.service";
import * as i6 from "@angular/forms";
import * as i7 from "../../../../../common/core/translations/translate.directive";
import * as i8 from "../../../../../common/core/translations/translations.service";
import * as i9 from "./canvas-background-drawer.component";
import * as i10 from "@ngxs/store";
import * as i11 from "../../../../image-editor/canvas/canvas.service";
var styles_CanvasBackgroundDrawerComponent = [i0.styles];
var RenderType_CanvasBackgroundDrawerComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CanvasBackgroundDrawerComponent, data: {} });
export { RenderType_CanvasBackgroundDrawerComponent as RenderType_CanvasBackgroundDrawerComponent };
export function View_CanvasBackgroundDrawerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "color-widget", [["class", "color-widget"], ["trans", ""]], [[2, "compact", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], null, null, i2.View_ColorWidgetComponent_0, i2.RenderType_ColorWidgetComponent)), i1.ɵdid(2, 49152, null, 0, i3.ColorWidgetComponent, [i4.OverlayPanel, i5.Settings], null, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.ColorWidgetComponent]), i1.ɵdid(4, 540672, null, 0, i6.FormControlDirective, [[8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR], [2, i6.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i6.NgControl, null, [i6.FormControlDirective]), i1.ɵdid(6, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), i1.ɵdid(7, 4341760, null, 0, i7.TranslateDirective, [i1.ElementRef, i8.Translations, i5.Settings], null, null), (_l()(), i1.ɵted(-1, 0, ["Canvas Background"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.colorControl; _ck(_v, 4, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).compact; var currVal_1 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 6).ngClassValid; var currVal_6 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_CanvasBackgroundDrawerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "canvas-background-drawer", [["class", "controls-drawer"]], null, null, null, View_CanvasBackgroundDrawerComponent_0, RenderType_CanvasBackgroundDrawerComponent)), i1.ɵdid(1, 114688, null, 0, i9.CanvasBackgroundDrawerComponent, [i10.Store, i11.CanvasService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CanvasBackgroundDrawerComponentNgFactory = i1.ɵccf("canvas-background-drawer", i9.CanvasBackgroundDrawerComponent, View_CanvasBackgroundDrawerComponent_Host_0, {}, {}, []);
export { CanvasBackgroundDrawerComponentNgFactory as CanvasBackgroundDrawerComponentNgFactory };
