var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UploadValidator } from '../../../../common/uploads/validation/upload-validator';
import { FileSizeValidation } from '../../../../common/uploads/validation/validations/file-size-validation';
import { AllowedExtensionsValidation } from '../../../../common/uploads/validation/validations/allowed-extensions-validation';
import { convertToBytes } from '../../../../common/core/utils/convertToBytes';
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/core/config/settings.service";
import * as i2 from "../../../../common/core/ui/toast.service";
import * as i3 from "../../../../common/core/translations/translations.service";
var ImportToolValidator = /** @class */ (function (_super) {
    __extends(ImportToolValidator, _super);
    function ImportToolValidator() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.DEFAULT_MAX_FILE_SIZE_MB = 10;
        _this.showToast = true;
        return _this;
    }
    ImportToolValidator.prototype.initValidations = function () {
        this.validations.push(new FileSizeValidation({ maxSize: this.getMaxFileSize() }, this.i18n));
        var allowedExtensions = this.getAllowedExtensions();
        if (allowedExtensions && allowedExtensions.length) {
            this.validations.push(new AllowedExtensionsValidation({ extensions: allowedExtensions }, this.i18n));
        }
    };
    ImportToolValidator.prototype.getMaxFileSize = function () {
        return this.settings.get('pixie.tools.import.maxFileSize', convertToBytes(this.DEFAULT_MAX_FILE_SIZE_MB, 'MB'));
    };
    ImportToolValidator.prototype.getAllowedExtensions = function () {
        return this.settings.get('pixie.tools.import.validExtensions');
    };
    ImportToolValidator.ngInjectableDef = i0.defineInjectable({ factory: function ImportToolValidator_Factory() { return new ImportToolValidator(i0.inject(i1.Settings), i0.inject(i2.Toast), i0.inject(i3.Translations)); }, token: ImportToolValidator, providedIn: "root" });
    return ImportToolValidator;
}(UploadValidator));
export { ImportToolValidator };
