var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Action, Selector, State } from '@ngxs/store';
import { CloseEditor, CloseForePanel, ContentLoaded, ObjectDeselected, ObjectSelected, OpenEditor, OpenPanel, SetZoom } from './editor-state-actions';
import { ObjectsState } from '../../image-editor-ui/state/objects/objects.state';
import { DrawerName } from '../../image-editor-ui/toolbar-controls/drawers/drawer-name.enum';
import { FilterState } from '../../image-editor-ui/state/filter/filter.state';
import { Settings } from '../../../common/core/config/settings.service';
import { EditorMode } from '../enums/editor-mode.enum';
import { EditorTheme } from '../enums/editor-theme.enum';
import { BreakpointsService } from '../../../common/core/ui/breakpoints.service';
import { ControlPosition } from '../enums/control-positions.enum';
import { ResizeState } from '../../image-editor-ui/state/resize/resize.state';
import { CropState } from '../../image-editor-ui/state/crop/crop.state';
import { ShapesState } from '../../image-editor-ui/state/shapes/shapes.state';
import { TransformState } from '../../image-editor-ui/state/transform/transform.state';
import { FrameState } from '../../image-editor-ui/state/frame/frame.state';
import { DrawState } from '../../image-editor-ui/state/draw/draw.state';
import { StickersState } from '../../image-editor-ui/state/stickers/stickers.state';
import { CornersState } from '../../image-editor-ui/state/corners/corners.state';
import { BackgroundState } from '../../image-editor-ui/state/background/background.state';
import { TextState } from '../../image-editor-ui/state/text/text.state';
import { ActiveObjectService } from '../canvas/active-object/active-object.service';
import { ObjectNames } from '../objects/object-names.enum';
var EditorState = /** @class */ (function () {
    function EditorState(config, breakpoints, activeObject) {
        this.config = config;
        this.breakpoints = breakpoints;
        this.activeObject = activeObject;
    }
    EditorState.visible = function (state) {
        return state.visible;
    };
    EditorState.mode = function (state) {
        return state.mode;
    };
    EditorState.theme = function (state) {
        return state.theme;
    };
    EditorState.controlsPosition = function (state) {
        return state.controlsPosition;
    };
    EditorState.toolbarHidden = function (state) {
        return state.hideToolbar;
    };
    EditorState.activePanel = function (state) {
        return state.activePanel;
    };
    EditorState.contentLoaded = function (state) {
        return state.contentLoaded;
    };
    EditorState.dirty = function (state) {
        var subState = state[state.activePanel];
        return subState.dirty;
    };
    EditorState.zoom = function (state) {
        return state.zoom;
    };
    EditorState.activeObjIsText = function (state) {
        return state.activeObjIsText;
    };
    EditorState.activeObjIsShape = function (state) {
        return state.activeObjIsShape;
    };
    EditorState.activeObjId = function (state) {
        return state.activeObjId;
    };
    EditorState.prototype.ngxsOnInit = function (ctx) {
        this.listenToConfigChange(ctx);
        this.listenToBreakpointChange(ctx);
    };
    EditorState.prototype.openEditor = function (ctx) {
        ctx.patchState({ visible: true });
        this.executeCallback('onOpen');
    };
    EditorState.prototype.closeEditor = function (ctx, _a) {
        var executeCallback = _a.executeCallback;
        var shouldClose = this.config.get('pixie.ui.allowEditorClose');
        if (executeCallback) {
            shouldClose = this.executeCallback('onClose') !== false;
        }
        if (shouldClose) {
            ctx.patchState({ visible: false });
        }
    };
    EditorState.prototype.openPanel = function (ctx, action) {
        ctx.patchState({
            activePanel: action.panel,
        });
    };
    EditorState.prototype.closeForePanel = function (ctx) {
        // navigation panel should always stay open
        ctx.patchState({ activePanel: DrawerName.NAVIGATION });
    };
    EditorState.prototype.objectSelected = function (ctx, action) {
        var state = this.getActiveObjState();
        // only open settings panel if selection event originated from
        // user clicking or tapping object on the canvas and not from
        // selecting object programmatically in the app
        if (action.fromUserAction && ctx.getState().activePanel === DrawerName.NAVIGATION) {
            state.activePanel = DrawerName.OBJECT_SETTINGS;
        }
        ctx.patchState(state);
        // sync active object form, when new object is selected
        this.activeObject.syncForm();
    };
    EditorState.prototype.objectDeselected = function (ctx) {
        var state = this.getActiveObjState();
        if (ctx.getState().activePanel === DrawerName.OBJECT_SETTINGS && !ctx.getState().objectSettings.dirty) {
            state.activePanel = DrawerName.NAVIGATION;
        }
        ctx.patchState(state);
        // sync active object form, when object is deselected
        this.activeObject.syncForm();
    };
    EditorState.prototype.contentLoaded = function (ctx) {
        ctx.patchState({ contentLoaded: true });
    };
    EditorState.prototype.setZoom = function (ctx, _a) {
        var zoom = _a.zoom;
        ctx.patchState({ zoom: zoom });
    };
    EditorState.prototype.executeCallback = function (name) {
        var callback = this.config.get('pixie.' + name);
        return callback && callback();
    };
    EditorState.prototype.listenToBreakpointChange = function (ctx) {
        var _this = this;
        this.breakpoints.isMobile$.subscribe(function (isMobile) {
            var position = _this.config.get('pixie.ui.nav.position', ControlPosition.TOP);
            ctx.patchState({
                controlsPosition: isMobile ? ControlPosition.BOTTOM : position
            });
        });
    };
    EditorState.prototype.listenToConfigChange = function (ctx) {
        var _this = this;
        // TODO: refactor after settings are stored in state
        this.config.onChange.subscribe(function () {
            var config = _this.config.get('pixie');
            ctx.patchState({
                mode: config.ui.mode,
                theme: config.ui.theme,
                hideToolbar: config.ui.toolbar.hide,
                visible: config.ui.visible,
            });
        });
    };
    EditorState.prototype.getActiveObjState = function () {
        var obj = this.activeObject.get();
        var state = {
            activeObjId: null,
            activeObjIsText: false,
            activeObjIsShape: false,
        };
        if (obj) {
            state.activeObjId = obj.data.id;
            state.activeObjIsText = obj.type === 'i-text';
            state.activeObjIsShape = obj.name === ObjectNames.shape.name;
        }
        return state;
    };
    __decorate([
        Action(OpenEditor),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EditorState.prototype, "openEditor", null);
    __decorate([
        Action(CloseEditor),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, CloseEditor]),
        __metadata("design:returntype", void 0)
    ], EditorState.prototype, "closeEditor", null);
    __decorate([
        Action(OpenPanel),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, OpenPanel]),
        __metadata("design:returntype", void 0)
    ], EditorState.prototype, "openPanel", null);
    __decorate([
        Action(CloseForePanel),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EditorState.prototype, "closeForePanel", null);
    __decorate([
        Action(ObjectSelected),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, ObjectSelected]),
        __metadata("design:returntype", void 0)
    ], EditorState.prototype, "objectSelected", null);
    __decorate([
        Action(ObjectDeselected),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EditorState.prototype, "objectDeselected", null);
    __decorate([
        Action(ContentLoaded),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EditorState.prototype, "contentLoaded", null);
    __decorate([
        Action(SetZoom),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetZoom]),
        __metadata("design:returntype", void 0)
    ], EditorState.prototype, "setZoom", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EditorState, "visible", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EditorState, "mode", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EditorState, "theme", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EditorState, "controlsPosition", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EditorState, "toolbarHidden", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EditorState, "activePanel", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EditorState, "contentLoaded", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EditorState, "dirty", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EditorState, "zoom", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EditorState, "activeObjIsText", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EditorState, "activeObjIsShape", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EditorState, "activeObjId", null);
    EditorState = __decorate([
        State({
            name: 'imageEditor',
            defaults: {
                visible: true,
                mode: EditorMode.INLINE,
                theme: EditorTheme.LIGHT,
                hideToolbar: false,
                activePanel: DrawerName.NAVIGATION,
                controlsPosition: ControlPosition.TOP,
                contentLoaded: false,
                activeObjIsText: false,
                activeObjIsShape: false,
                activeObjId: null,
                zoom: 100,
            },
            children: [
                FilterState,
                ResizeState,
                CropState,
                TransformState,
                DrawState,
                TextState,
                ShapesState,
                StickersState,
                FrameState,
                CornersState,
                BackgroundState,
                ObjectsState
            ],
        }),
        __metadata("design:paramtypes", [Settings,
            BreakpointsService,
            ActiveObjectService])
    ], EditorState);
    return EditorState;
}());
export { EditorState };
