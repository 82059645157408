var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Settings } from 'common/core/config/settings.service';
import { FilterToolService } from '../../../../image-editor/tools/filter/filter-tool.service';
import { Select, Store } from '@ngxs/store';
import { EditorState } from '../../../../image-editor/state/editor-state';
import { Observable } from 'rxjs';
import { OpenFilterControls } from '../../../state/filter/filter.actions';
import { FilterState } from '../../../state/filter/filter.state';
import { startCase } from '../../../../../common/core/utils/start-case';
var FilterDrawerComponent = /** @class */ (function () {
    function FilterDrawerComponent(settings, filterTool, store, config) {
        this.settings = settings;
        this.filterTool = filterTool;
        this.store = store;
        this.config = config;
        this.filterList = [];
        this.filterList = this.config.get('pixie.tools.filter.items');
    }
    FilterDrawerComponent.prototype.applyFilter = function (filter) {
        this.filterTool.apply(filter);
    };
    FilterDrawerComponent.prototype.removeFilter = function (filter) {
        this.filterTool.remove(filter);
    };
    FilterDrawerComponent.prototype.getFilterImage = function (filter) {
        return this.settings.getAssetUrl('images/filters/square/' + filter.replace(' ', '-') + '.jpg');
    };
    FilterDrawerComponent.prototype.showFilterControls = function (filter) {
        this.store.dispatch(new OpenFilterControls(filter));
    };
    FilterDrawerComponent.prototype.getFilterDisplayName = function (name) {
        if (name === 'blackWhite')
            return 'Black & White';
        return startCase(name);
    };
    __decorate([
        Select(EditorState.activePanel),
        __metadata("design:type", Observable)
    ], FilterDrawerComponent.prototype, "forePanel$", void 0);
    __decorate([
        Select(FilterState.activeFilters),
        __metadata("design:type", Observable)
    ], FilterDrawerComponent.prototype, "activeFilters$", void 0);
    __decorate([
        Select(FilterState.selectedFilter),
        __metadata("design:type", Observable)
    ], FilterDrawerComponent.prototype, "selectedFilter$", void 0);
    __decorate([
        Select(FilterState.dirty),
        __metadata("design:type", Observable)
    ], FilterDrawerComponent.prototype, "dirty$", void 0);
    return FilterDrawerComponent;
}());
export { FilterDrawerComponent };
