/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./image-editor-ui/image-editor.component.ngfactory";
import * as i3 from "./image-editor-ui/image-editor.component";
import * as i4 from "./image-editor/canvas/canvas.service";
import * as i5 from "./image-editor/history/history-tool.service";
import * as i6 from "./image-editor-ui/toolbar-controls/editor-controls.service";
import * as i7 from "../common/core/ui/breakpoints.service";
import * as i8 from "./image-editor-ui/toolbar-controls/floating-panels.service";
import * as i9 from "./image-editor/canvas/canvas-keybinds.service";
import * as i10 from "./image-editor/canvas/active-object/active-object.service";
import * as i11 from "./image-editor/canvas/canvas-state.service";
import * as i12 from "../common/core/config/settings.service";
import * as i13 from "@ngxs/store";
import * as i14 from "../common/core/translations/translations.service";
import * as i15 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: { "animation": [{ type: 7, name: "editorVisible", definitions: [{ type: 0, name: "true", styles: { type: 6, styles: { opacity: "*", display: "block" }, offset: null }, options: undefined }, { type: 0, name: "false", styles: { type: 6, styles: { opacity: "0", display: "none" }, offset: null }, options: undefined }, { type: 1, expr: "true <=> false", animation: { type: 4, styles: null, timings: "325ms cubic-bezier(.4,0,.2,1)" }, options: null }], options: {} }] } });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { overlay: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "image-editor", [], null, null, null, i2.View_ImageEditorComponent_0, i2.RenderType_ImageEditorComponent)), i1.ɵdid(2, 114688, null, 0, i3.ImageEditorComponent, [i4.CanvasService, i5.HistoryToolService, i6.EditorControlsService, i7.BreakpointsService, i8.FloatingPanelsService, i9.CanvasKeybindsService, i1.ElementRef, i10.ActiveObjectService, i11.CanvasStateService, i12.Settings, i13.Store, i14.Translations], null, null), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["overlay", 1]], null, 0, "div", [["class", "overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "loader"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pixie-editor", [], [[40, "@editorVisible", 0], [2, "theme-dark", null], [2, "theme-light", null], [2, "mode-overlay", null], [2, "mode-inline", null], [4, "width", null], [4, "height", null], [2, "ui-compact", null]], null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i15.AppComponent, [i1.ElementRef, i13.Store, i12.Settings], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).animate; var currVal_1 = i1.ɵnov(_v, 1).darkTheme; var currVal_2 = i1.ɵnov(_v, 1).lightTheme; var currVal_3 = i1.ɵnov(_v, 1).overlayMode; var currVal_4 = i1.ɵnov(_v, 1).inlineMode; var currVal_5 = i1.ɵnov(_v, 1).width; var currVal_6 = i1.ɵnov(_v, 1).height; var currVal_7 = i1.ɵnov(_v, 1).compact; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
var AppComponentNgFactory = i1.ɵccf("pixie-editor", i15.AppComponent, View_AppComponent_Host_0, {}, {}, ["*"]);
export { AppComponentNgFactory as AppComponentNgFactory };
