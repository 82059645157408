var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function normalizeObjectProps(obj) {
    var copy = __assign({}, obj);
    // no need to apply shadow, if it won't be visible
    if (copy.shadow && copy.shadow.offsetX === -1) {
        delete copy.shadow;
    }
    return copy;
}
