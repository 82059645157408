var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ElementRef, OnInit } from '@angular/core';
import { CanvasService } from '../image-editor/canvas/canvas.service';
import { HistoryToolService } from '../image-editor/history/history-tool.service';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EditorControlsService } from './toolbar-controls/editor-controls.service';
import { FloatingPanelsService } from './toolbar-controls/floating-panels.service';
import { CanvasKeybindsService } from '../image-editor/canvas/canvas-keybinds.service';
import { ActiveObjectService } from '../image-editor/canvas/active-object/active-object.service';
import { CanvasStateService } from '../image-editor/canvas/canvas-state.service';
import { Settings } from 'common/core/config/settings.service';
import { BreakpointsService } from '../../common/core/ui/breakpoints.service';
import { Select, Store } from '@ngxs/store';
import { ObjectDeselected, ObjectSelected, OpenPanel } from '../image-editor/state/editor-state-actions';
import { EditorState } from '../image-editor/state/editor-state';
import { DrawerName } from './toolbar-controls/drawers/drawer-name.enum';
import { Localization } from '../../common/core/types/models/Localization';
import { Translations } from '../../common/core/translations/translations.service';
var ImageEditorComponent = /** @class */ (function () {
    function ImageEditorComponent(canvas, history, controls, breakpoints, floatingPanels, canvasKeybinds, el, activeObject, state, config, store, i18n) {
        this.canvas = canvas;
        this.history = history;
        this.controls = controls;
        this.breakpoints = breakpoints;
        this.floatingPanels = floatingPanels;
        this.canvasKeybinds = canvasKeybinds;
        this.el = el;
        this.activeObject = activeObject;
        this.state = state;
        this.config = config;
        this.store = store;
        this.i18n = i18n;
    }
    ImageEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state.wrapperEl = this.canvasWrapper.nativeElement;
        this.state.maskWrapperEl = this.canvasMaskWrapper.nativeElement;
        // update editor language on settings change
        this.setLocalization();
        this.config.onChange.subscribe(function () {
            _this.setLocalization();
        });
        this.canvas.init().subscribe(function () {
            _this.activeObject.init();
            _this.canvasKeybinds.init();
            _this.fitCanvasToScreenOnResize();
            _this.openObjectSettingsOnDoubleClick();
            _this.closePanelsOnObjectDelete();
            _this.handleObjectSelection();
            _this.updateHistoryOnObjectModification();
            _this.canvasMaskWrapper.nativeElement.classList.remove('not-loaded');
        });
    };
    ImageEditorComponent.prototype.closePanelsOnObjectDelete = function () {
        var _this = this;
        this.canvas.fabric().on('object:delete', function () { return _this.controls.closeCurrentPanel(); });
    };
    ImageEditorComponent.prototype.openObjectSettingsOnDoubleClick = function () {
        var _this = this;
        this.canvas.fabric().on('mouse:dblclick', function () {
            if (!_this.activeObject.getId() || _this.store.selectSnapshot(EditorState.dirty))
                return;
            _this.store.dispatch(new OpenPanel(DrawerName.OBJECT_SETTINGS));
        });
    };
    /**
     * Replace current history item, so object position is
     * updated after object is scaled, moved or rotated.
     */
    ImageEditorComponent.prototype.updateHistoryOnObjectModification = function () {
        var _this = this;
        this.canvas.fabric().on('object:modified', function (event) {
            if (!event.e || _this.store.selectSnapshot(EditorState.dirty))
                return;
            _this.history.replaceCurrent();
        });
    };
    ImageEditorComponent.prototype.handleObjectSelection = function () {
        var _this = this;
        this.canvas.fabric().on('selection:created', function (e) { return _this.onObjectSelection(e); });
        this.canvas.fabric().on('selection:updated', function (e) { return _this.onObjectSelection(e); });
        this.canvas.fabric().on('selection:cleared', function (fabricEvent) {
            _this.store.dispatch(new ObjectDeselected(fabricEvent.e != null));
        });
    };
    ImageEditorComponent.prototype.onObjectSelection = function (fabricEvent) {
        this.store.dispatch(new ObjectSelected(fabricEvent.target.name, fabricEvent.e != null));
    };
    ImageEditorComponent.prototype.fitCanvasToScreenOnResize = function () {
        var _this = this;
        fromEvent(window, 'resize')
            .pipe(debounceTime(200), distinctUntilChanged())
            .subscribe(function () {
            _this.canvas.zoom.fitToScreen();
        });
    };
    ImageEditorComponent.prototype.setLocalization = function () {
        var active = this.config.get('pixie.languages.active', 'default');
        if (active === 'default')
            return;
        this.config.set('i18n.enable', true);
        var lines = this.config.get("pixie.languages.custom." + active);
        this.i18n.setLocalization({
            model: new Localization({ name: active }),
            lines: lines,
        });
    };
    __decorate([
        Select(EditorState.controlsPosition),
        __metadata("design:type", Observable)
    ], ImageEditorComponent.prototype, "controlsPosition$", void 0);
    __decorate([
        Select(EditorState.toolbarHidden),
        __metadata("design:type", Observable)
    ], ImageEditorComponent.prototype, "toolbarHidden$", void 0);
    __decorate([
        Select(EditorState.contentLoaded),
        __metadata("design:type", Observable)
    ], ImageEditorComponent.prototype, "contentLoaded$", void 0);
    return ImageEditorComponent;
}());
export { ImageEditorComponent };
