var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Selector, State, Store } from '@ngxs/store';
import { HistoryToolService } from '../../../image-editor/history/history-tool.service';
import { CloseForePanel } from '../../../image-editor/state/editor-state-actions';
import { HistoryNames } from '../../../image-editor/history/history-names.enum';
import { BaseToolState } from '../base-tool.state';
import { DrawerName } from '../../toolbar-controls/drawers/drawer-name.enum';
import { CropToolService } from '../../../image-editor/tools/crop/crop-tool.service';
import { CropZoneService } from '../../../image-editor/tools/crop/crop-zone.service';
var CROP_STATE_DEFAULTS = {
    dirty: true,
};
var CropState = /** @class */ (function (_super) {
    __extends(CropState, _super);
    function CropState(store, history, actions$, cropTool, cropZone) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this.history = history;
        _this.actions$ = actions$;
        _this.cropTool = cropTool;
        _this.cropZone = cropZone;
        _this.toolName = DrawerName.CROP;
        return _this;
    }
    CropState.dirty = function (state) {
        return state.dirty;
    };
    CropState.prototype.applyChanges = function (ctx) {
        var _this = this;
        this.store.dispatch(new CloseForePanel());
        this.cropTool.apply(this.cropZone.getSize()).then(function () {
            _this.history.add(HistoryNames.CROP);
            ctx.patchState(CROP_STATE_DEFAULTS);
        });
    };
    CropState.prototype.cancelChanges = function (ctx) {
        this.store.dispatch(new CloseForePanel());
        ctx.patchState(CROP_STATE_DEFAULTS);
    };
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], CropState, "dirty", null);
    CropState = __decorate([
        State({
            name: 'crop',
            defaults: CROP_STATE_DEFAULTS
        }),
        __metadata("design:paramtypes", [Store,
            HistoryToolService,
            Actions,
            CropToolService,
            CropZoneService])
    ], CropState);
    return CropState;
}(BaseToolState));
export { CropState };
