var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Settings } from 'common/core/config/settings.service';
import * as WebFont from 'webfontloader';
import { FontsPaginatorService } from './fonts-paginator.service';
import { map } from 'rxjs/operators';
import { HttpCacheClient } from 'common/core/http/http-cache-client';
var GoogleFontsPanelService = /** @class */ (function () {
    function GoogleFontsPanelService(http, settings, paginator) {
        var _this = this;
        this.http = http;
        this.settings = settings;
        this.paginator = paginator;
        this.loadedFonts = [];
        this.paginator.current$.subscribe(function (fonts) {
            _this.loadIntoDom(fonts);
        });
    }
    GoogleFontsPanelService.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var allFonts, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        allFonts = this.settings.get('pixie.tools.text.items', []);
                        if (!!this.settings.get('pixie.tools.text.replaceDefault')) return [3 /*break*/, 2];
                        _b = (_a = allFonts).concat;
                        return [4 /*yield*/, this.getGoogleFonts()];
                    case 1:
                        allFonts = _b.apply(_a, [_c.sent()]);
                        _c.label = 2;
                    case 2:
                        this.paginator.setFonts(allFonts);
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Load specified google fonts into the DOM.
     */
    GoogleFontsPanelService.prototype.loadIntoDom = function (names, context) {
        var _this = this;
        if (names === void 0) { names = []; }
        return new Promise(function (resolve) {
            if (!names || !names.length)
                return resolve();
            // remove already loaded fonts
            names = names.filter(function (curr) { return !_this.loadedFonts.find(function (font) { return font.family === curr.family; }); });
            if (!names.length)
                return resolve();
            _this.loadedFonts = _this.loadedFonts.concat(names);
            var google = names.filter(function (font) { return font.type === 'google'; });
            var custom = names.filter(function (font) { return font.type === 'custom'; });
            var config = {
                active: resolve,
                context: context,
                classes: false,
            };
            if (google.length) {
                config['google'] = { families: google.map(function (font) { return font.family; }) };
            }
            if (custom.length) {
                _this.loadCustomFonts(custom, context);
                config['custom'] = { families: custom.map(function (font) { return font.family; }) };
            }
            WebFont.load(config);
        });
    };
    /**
     * Load custom fonts via font-face css property.
     */
    GoogleFontsPanelService.prototype.loadCustomFonts = function (fonts, context) {
        var _this = this;
        if (fonts === void 0) { fonts = []; }
        if (!fonts.length)
            return;
        var style = '';
        fonts.forEach(function (font) {
            var url = _this.settings.getAssetUrl('fonts/' + font.filePath);
            style += '@font-face { font-family: ' + font.family + '; src: url("' + url + '"); }';
        });
        var styleEl = document.createElement('style');
        styleEl.innerHTML = style;
        (context || window).document.head.appendChild(styleEl);
    };
    /**
     * Get list of all google fonts.
     */
    GoogleFontsPanelService.prototype.getGoogleFonts = function () {
        var key = this.settings.get('pixie.googleFontsApiKey');
        // TODO: fix the type issues here sometime
        return this.http.getWithCache('https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=' + key)
            .pipe(map(function (response) {
            return response['items'].map(function (font) {
                return { family: font.family, category: font.category, type: 'google' };
            });
        })).toPromise();
    };
    return GoogleFontsPanelService;
}());
export { GoogleFontsPanelService };
