/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navigation-bar.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../common/core/translations/translate.directive";
import * as i5 from "../../../../common/core/translations/translations.service";
import * as i6 from "../../../../common/core/config/settings.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../../common/core/ui/custom-scrollbar/custom-scrollbar.directive";
import * as i9 from "../../../../common/core/ui/breakpoints.service";
import * as i10 from "@angular/cdk/observers";
import * as i11 from "../../../../common/core/ui/custom-scrollbar/supports-native-scrollbar-styling";
import * as i12 from "./navigation-bar.component";
import * as i13 from "../editor-controls.service";
import * as i14 from "../../../image-editor/canvas/canvas.service";
import * as i15 from "../../../image-editor/tools/export/export-tool.service";
import * as i16 from "../../../image-editor/tools/merge/merge-tool.service";
import * as i17 from "../../../image-editor/history/history-tool.service";
import * as i18 from "../../../../common/core/ui/toast.service";
import * as i19 from "@ngxs/store";
var styles_NavigationBarComponent = [i0.styles];
var RenderType_NavigationBarComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_NavigationBarComponent, data: {} });
export { RenderType_NavigationBarComponent as RenderType_NavigationBarComponent };
function View_NavigationBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "separator"]], null, null, null, null, null))], null, null); }
function View_NavigationBarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "no-style control-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.executeNavItemAction(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "name"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(4, 4341760, null, 0, i4.TranslateDirective, [i1.ElementRef, i5.Translations, i6.Settings], null, null), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_3 = _v.parent.context.$implicit.icon; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navItemIsDisabled(_v.parent.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 2).inline; var currVal_2 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_4 = (_v.parent.context.$implicit.display_name || _v.parent.context.$implicit.name); _ck(_v, 5, 0, currVal_4); }); }
function View_NavigationBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationBarComponent_2)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationBarComponent_3)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type === "separator"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type !== "separator"); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_NavigationBarComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "scroll-container"], ["customScrollbar", ""]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i8.CustomScrollbarDirective, [i1.ElementRef, i1.Renderer2, i9.BreakpointsService, i1.NgZone, i10.MutationObserverFactory, i6.Settings, i11.SUPPORTS_NATIVE_SCROLLBAR_STYLING], { suppressX: [0, "suppressX"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationBarComponent_1)), i1.ɵdid(3, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.getNavItems(); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_NavigationBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "navigation-bar", [], null, null, null, View_NavigationBarComponent_0, RenderType_NavigationBarComponent)), i1.ɵdid(1, 114688, null, 0, i12.NavigationBarComponent, [i13.EditorControlsService, i14.CanvasService, i15.ExportToolService, i6.Settings, i16.MergeToolService, i17.HistoryToolService, i18.Toast, i19.Store, i19.Actions, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavigationBarComponentNgFactory = i1.ɵccf("navigation-bar", i12.NavigationBarComponent, View_NavigationBarComponent_Host_0, {}, {}, []);
export { NavigationBarComponentNgFactory as NavigationBarComponentNgFactory };
