/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./color-controls-drawer.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../widgets/color-widget/color-widget.component.ngfactory";
import * as i3 from "../widgets/color-widget/color-widget.component";
import * as i4 from "../../../../common/core/ui/overlay-panel/overlay-panel.service";
import * as i5 from "../../../../common/core/config/settings.service";
import * as i6 from "@angular/forms";
import * as i7 from "./color-controls-drawer.component";
import * as i8 from "../../../image-editor/canvas/active-object/active-object.service";
var styles_ColorControlsDrawerComponent = [i0.styles];
var RenderType_ColorControlsDrawerComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ColorControlsDrawerComponent, data: {} });
export { RenderType_ColorControlsDrawerComponent as RenderType_ColorControlsDrawerComponent };
export function View_ColorControlsDrawerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "color-widget", [["class", "color-widget"]], [[2, "compact", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], null, null, i2.View_ColorWidgetComponent_0, i2.RenderType_ColorWidgetComponent)), i1.ɵdid(1, 49152, null, 0, i3.ColorWidgetComponent, [i4.OverlayPanel, i5.Settings], null, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.ColorWidgetComponent]), i1.ɵdid(3, 540672, null, 0, i6.FormControlDirective, [[8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR], [2, i6.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i6.NgControl, null, [i6.FormControlDirective]), i1.ɵdid(5, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), i1.ɵncd(0, 0)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.activeObject.form.get(_co.controlName); _ck(_v, 3, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).compact; var currVal_1 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 5).ngClassValid; var currVal_6 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_ColorControlsDrawerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "color-controls-drawer", [], null, null, null, View_ColorControlsDrawerComponent_0, RenderType_ColorControlsDrawerComponent)), i1.ɵdid(1, 49152, null, 0, i7.ColorControlsDrawerComponent, [i8.ActiveObjectService], null, null)], null, null); }
var ColorControlsDrawerComponentNgFactory = i1.ɵccf("color-controls-drawer", i7.ColorControlsDrawerComponent, View_ColorControlsDrawerComponent_Host_0, { controlName: "controlName" }, {}, ["*"]);
export { ColorControlsDrawerComponentNgFactory as ColorControlsDrawerComponentNgFactory };
